import { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Divider, Link, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useAppSelector } from '@hooks'
import { isUserAuthenticatedSelector } from '@reducers/authSlice'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import SignupForm from '@/components/auth/SignupForm'
import SocialAuth from '@/components/auth/SocialAuth'
import Wrapper from '@/components/elements/Wrapper'
import Logo from '@/components/elements/auth/Logo'
import AppBar from '@/components/elements/navigation/AppBar'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const Signup = () => {
  const authenticated = useAppSelector(isUserAuthenticatedSelector)

  useEffect(() => {
    // redirect to home if already logged in
    if (authenticated) {
      history.navigate(routes.home)
    }
  }, [authenticated])

  return (
    <>
      <AppBar title="Login" linkBackTo={routes.signin} />

      <Wrapper>
        <style.Content>
          <style.Heading component={motion.div} {...style.fadeInUp}>
            <Logo />

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              Enter your details below.
            </Typography>
          </style.Heading>

          <Box component={motion.div} {...style.fadeInUp}>
            <SocialAuth />
          </Box>

          <Divider sx={{ my: 3 }} component={motion.div} {...style.fadeInUp}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              OR
            </Typography>
          </Divider>

          <SignupForm />

          <Typography
            component={motion.p}
            {...style.fadeInUp}
            variant="body2"
            align="center"
            sx={{ color: 'text.secondary', mt: 2 }}
          >
            By registering, I agree to{' '}
            <Link
              underline="hover"
              color={typography.colors.blue}
              component={RouterLink}
              to={routes.termsAndConditions}
            >
              Terms of Service
            </Link>{' '}
            &{' '}
            <Link
              underline="hover"
              color={typography.colors.blue}
              component={RouterLink}
              to={routes.privacyPolicy}
            >
              Privacy Policy
            </Link>
            .
          </Typography>

          <Typography
            component={motion.p}
            {...style.fadeInUp}
            variant="body2"
            align="center"
            sx={{ mt: 3 }}
          >
            Have an account?{' '}
            <Link
              variant="subtitle2"
              color={typography.colors.blue}
              underline="hover"
              component={RouterLink}
              to={routes.signin}
            >
              Login
            </Link>
          </Typography>
        </style.Content>
      </Wrapper>
    </>
  )
}

export default Signup
