import { Link } from 'react-router-dom'
import { Box } from '@mui/material'

const Logo = () => {
  return (
    <Box>
      <Link to="/">
        <Box
          component="img"
          src="rona-learning-studio@3x.png"
          alt="logo"
          sx={{ width: 150 }}
        />
      </Link>
    </Box>
  )
}

export default Logo
