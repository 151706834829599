import { Link as RouterLink } from 'react-router-dom'
import { Link, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import routes from '@/pages/routes'
import ForgotPasswordForm from '@/components/auth/ForgotPasswordForm'
import Wrapper from '@/components/elements/Wrapper'
import Logo from '@/components/elements/auth/Logo'
import AppBar from '@/components/elements/navigation/AppBar'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const ForgotPassword = () => {
  return (
    <>
      <AppBar title="Login" linkBackTo={routes.signin} />

      <Wrapper>
        <style.Content>
          <style.Heading component={motion.div} {...style.fadeInUp}>
            <Logo />
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              Forgot your password?
            </Typography>
            <Typography
              component={motion.p}
              {...style.fadeInUp}
              variant="body2"
              align="center"
              sx={{ mt: 3, mb: 3 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              To reset your password, please enter your email. {'\n'}We will
              send the password reset instructions to the email address. {'\n'}
              If your email address is no longer valid, please{' '}
              <Link
                component={RouterLink}
                to={routes.support}
                variant="subtitle2"
                color={typography.colors.blue}
                underline="hover"
              >
                Contact Us
              </Link>{' '}
              for further assistance.
            </Typography>
          </style.Heading>

          <ForgotPasswordForm />
        </style.Content>
      </Wrapper>
    </>
  )
}

export default ForgotPassword
