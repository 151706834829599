import { isValidIds } from '@utils/isValidIds'
import { validation } from '@utils/validation'
import AppType from '@/types/AppType'
import { getAuth } from '@firebase/auth'
import { get, getDatabase, ref, serverTimestamp, set } from '@firebase/database'

const getCurrentUser = async () => {
  try {
    const auth = getAuth()
    return auth.currentUser
  } catch (error) {
    console.error(error)
    throw error
  }
}

const initUserData = async (userId: string) => {
  try {
    if (!isValidIds(userId)) throw new Error('User is not authenticated.')

    const db = getDatabase()
    const userRef = ref(db, `/users/${userId}`)
    const snapshot = await get(userRef)

    if (snapshot.exists()) {
      const data = snapshot.val()
      return data
    } else {
      throw new Error('User data not found.')
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

const addUserToDB = async (user: AppType.User) => {
  try {
    if (!isValidIds(user.id))
      throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const userRef = ref(db, `users/${user.id}`)

    await set(userRef, user)
  } catch (error) {
    console.error(error)
    throw error
  }
}

const updateUserName = async (userId: string, name: string) => {
  try {
    if (!isValidIds(userId)) throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const userRef = ref(db, `users/${userId}/displayName`)

    await set(userRef, name)
  } catch (error) {
    console.error(error)
    throw error
  }
}

const deleteAccount = async (userId: string) => {
  try {
    if (!isValidIds(userId)) throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const userRef = ref(db, `users/${userId}/deletedAt`)

    await set(userRef, serverTimestamp())
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const userAPI = {
  getCurrentUser,
  initUserData,
  addUserToDB,
  updateUserName,
  deleteAccount,
}
