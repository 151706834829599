import { Typography } from '@mui/material'
import Wrapper from '@/components/elements/Wrapper'
import Nav from '@/components/elements/navigation/Nav'

const TermsAndConditionsPage: React.FC = () => {
  return (
    <>
      <Nav />
      <Wrapper>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="caption" gutterBottom>
          Last Updated: 9/1/2023
        </Typography>
        <Typography variant="body1" gutterBottom>
          Welcome to <strong>RONA Team</strong>, a language translation and
          learning app developed by <strong>RONA Team</strong> ("we," "us," or
          "our"). By accessing or using our mobile application ("App") and
          related services, you agree to comply with and be bound by the
          following Terms and Conditions. If you do not agree with these terms,
          please do not use our App.
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. Use of the App
        </Typography>
        <Typography variant="body1" gutterBottom>
          a. You must be at least 13 years old to use our App. If you are under
          13, you may not use the App.
        </Typography>
        <Typography variant="body1" gutterBottom>
          b. You agree to use the App for lawful purposes and in accordance with
          these Terms and Conditions.
        </Typography>
        <Typography variant="body1" gutterBottom>
          c. You are solely responsible for maintaining the confidentiality of
          your account information and for all activities that occur under your
          account.
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. Intellectual Property
        </Typography>
        <Typography variant="body1" gutterBottom>
          a. The content, features, and functionality of the App, including but
          not limited to text, graphics, logos, icons, images, audio clips, and
          software, are the property of <strong>RONA Team</strong> and are
          protected by international copyright, trademark, and other
          intellectual property laws.
        </Typography>
        <Typography variant="body1" gutterBottom>
          b. You may not reproduce, distribute, modify, create derivative works
          of, publicly display, or perform any of the content from the App
          without our prior written consent.
        </Typography>
        <Typography variant="h6" gutterBottom>
          3. User Content
        </Typography>
        <Typography variant="body1" gutterBottom>
          a. By using the App, you may submit content, including translations,
          texts, and other materials ("User Content"). You retain ownership of
          your User Content.
        </Typography>
        <Typography variant="body1" gutterBottom>
          b. You grant <strong>RONA Team</strong> a non-exclusive, royalty-free,
          worldwide, perpetual, and irrevocable license to use, reproduce,
          modify, adapt, publish, translate, distribute, and display your User
          Content for the purpose of operating and improving the App.
        </Typography>
        <Typography variant="h6" gutterBottom>
          4. Prohibited Activities
        </Typography>
        <Typography variant="body1" gutterBottom>
          You agree not to:
        </Typography>
        <Typography variant="body1" gutterBottom>
          a. Use the App for any unlawful or unauthorized purpose.
        </Typography>
        <Typography variant="body1" gutterBottom>
          b. Attempt to gain unauthorized access to the App or its related
          systems.
        </Typography>
        <Typography variant="body1" gutterBottom>
          c. Interfere with or disrupt the App or its servers or networks.
        </Typography>
        <Typography variant="body1" gutterBottom>
          d. Use any automated system, including "bots," to access or interact
          with the App.
        </Typography>
        <Typography variant="h6" gutterBottom>
          5. Limitation of Liability
        </Typography>
        <Typography variant="body1" gutterBottom>
          a. <strong>RONA Team</strong> and its affiliates, officers, directors,
          employees, and agents shall not be liable for any indirect,
          incidental, special, consequential, or punitive damages arising out of
          or in connection with the use or inability to use the App.
        </Typography>
        <Typography variant="body1" gutterBottom>
          b. In no event shall our total liability exceed the amount paid by
          you, if any, for accessing or using the App.
        </Typography>
        <Typography variant="h6" gutterBottom>
          6. Privacy
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your use of the App is also governed by our Privacy Policy, which can
          be found within the App and on our website.
        </Typography>
        <Typography variant="h6" gutterBottom>
          7. Changes to Terms and Conditions
        </Typography>
        <Typography variant="body1" gutterBottom>
          We reserve the right to update or modify these Terms and Conditions at
          any time without prior notice. By continuing to use the App after such
          changes, you agree to be bound by the revised Terms and Conditions.
        </Typography>
        <Typography variant="h6" gutterBottom>
          8. Termination
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may terminate or suspend your access to the App at our sole
          discretion, without prior notice and for any reason, including if we
          believe you have violated these Terms and Conditions.
        </Typography>
        <Typography variant="h6" gutterBottom>
          9. Governing Law
        </Typography>
        <Typography variant="body1" gutterBottom>
          These Terms and Conditions are governed by and construed in accordance
          with the laws of [Jurisdiction], without regard to its conflict of law
          principles.
        </Typography>
        <br />
        <Typography variant="h6" gutterBottom>
          Thank you for using RONA Team!
        </Typography>
        <br />
      </Wrapper>
    </>
  )
}

export default TermsAndConditionsPage
