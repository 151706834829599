import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Box,
  IconButton,
  AppBar as MaterialAppBar,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material'
import Logo from '@/components/elements/navigation/Logo'

type AppBarProps = {
  title?: string
  linkBackTo?: string
  actions?: ReactNode
}

const AppBar = (props: AppBarProps) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 40,
  })

  return (
    <MaterialAppBar
      elevation={trigger ? 4 : 0}
      position="sticky"
      color="default"
    >
      <Toolbar>
        {props.linkBackTo && (
          <IconButton
            component={Link}
            to={props.linkBackTo}
            color="primary"
            edge="start"
          >
            <ArrowBackIcon titleAccess="Navigate Back" />
          </IconButton>
        )}
        {!props.linkBackTo && <Logo />}
        <Box ml={3} flex="auto">
          <Typography variant="h6" color="primary">
            {props.title}
          </Typography>
        </Box>
        {props.actions}
      </Toolbar>
    </MaterialAppBar>
  )
}

export default AppBar
