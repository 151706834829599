import AppType from '@/types/AppType'
import { languageAPI } from '@/db/languageAPI'

const addLanguageToDB = async (language: AppType.Language, userId: string) => {
  return await languageAPI.addLanguageToDB(language, userId)
}

const addRecentLanguagesToDB = async (
  recentLanguages: AppType.LanguageOption[],
  userId: string,
) => {
  return await languageAPI.addRecentLanguagesToDB(recentLanguages, userId)
}

export const languageService = {
  addLanguageToDB,
  addRecentLanguagesToDB,
}
