export const isObjectsEqual = (obj1: any, obj2: any): boolean => {
  // Check if both objects are the same type
  if (typeof obj1 !== typeof obj2) {
    return false
  }

  // Check if both objects are null or undefined
  if (
    obj1 === null ||
    obj1 === undefined ||
    obj2 === null ||
    obj2 === undefined
  ) {
    return obj1 === obj2
  }

  // Check if both objects are non-object types (e.g., number, string, boolean)
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false
  }

  // Iterate through the keys and check the values recursively
  for (const key of keys1) {
    if (!isObjectsEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  // If all checks passed, the objects are equal
  return true
}
