import { toast } from 'react-toastify'
import emailjs from '@emailjs/browser'

const emailjsConfig = {
  serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID || '',
  templateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '',
  publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY || '',
}

const message = {
  success: 'Your request has been successfully submitted!',
  error: 'Sorry, something went wrong with the email submission.',
}

const sendEmail = async (formData: Record<string, unknown>) => {
  try {
    await emailjs
      .send(
        emailjsConfig.serviceId,
        emailjsConfig.templateId,
        formData,
        emailjsConfig.publicKey,
      )
      .then(
        (result) => {
          toast.success(message.success)
          console.log(result.text)
        },
        (error) => {
          toast.error(message.error)
          console.log(error.text)
        },
      )
  } catch (error) {
    toast.error(message.error)
    console.error(error)
    return error
  }
}

export default sendEmail
