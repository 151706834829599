import { ReactNode } from 'react'
import { Box, BoxProps } from '@mui/material'

type FlexboxProps = {
  children: ReactNode
  flexDirection?: 'row' | 'column'
  justifyContent?: 'center' | 'space-between' | 'space-around'
} & BoxProps

const Flexbox = (props: FlexboxProps) => {
  const {
    children,
    flexDirection = 'column',
    justifyContent = 'center',
    ...rest
  } = props

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent,
        flexDirection,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Flexbox
