const colors = {
  // main
  primary: '#00B386',
  secondary: '#161f28',
  surface: '#1B2732',
  // colors
  white: '#FFFFFF',
  grey: '#4E5A65',
  greyLight: '#CAD9E6',
  greyDark: '#3F444C',
  transparent: 'transparent',
  green: '#2BC480',
  red: '#f13a59',
  blue: '#1877F2',
  black: '#000000',
  google: '#DF3E30',
  // elements
  card: '#E7F3FE',
}

const font = {
  primary: 'Helvetica',
}

const typography = {
  colors,
  font,
}

export { typography }
