import { useState } from 'react'
import { toast } from 'react-toastify'
import { Form, FormikProvider, useFormik } from 'formik'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material'
import { motion } from 'framer-motion'
import { AppDispatch } from '@store'
import { useAppDispatch } from '@hooks'
import { register } from '@reducers/authSlice'
import { addNewUser } from '@reducers/user/userSlice'
import { validation } from '@utils/validation'
import AppType from '@/types/AppType'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

type State = {
  displayName: string
  email: string
  password: string
}

const handleSignup = async (userData: State, dispatch: AppDispatch) => {
  try {
    const registerAction = await dispatch(register(userData))

    if (register.fulfilled.match(registerAction)) {
      const user: AppType.User = {
        ...registerAction.payload,
        displayName: registerAction.payload.displayName ?? userData.displayName,
      }
      const addNewUserAction = await dispatch(addNewUser(user))

      if (addNewUser.fulfilled.match(addNewUserAction)) {
        toast.success('Welcome!')
      } else {
        toast.error(validation.errorMessages.somethingWrong)
      }
    }
  } catch (error) {
    console.error('Signup or dispatch failed')
  }
}

const SignupForm = () => {
  const dispatch = useAppDispatch()
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: validation.SignupSchema,
    onSubmit: async (values, actions) => {
      const userData: State = {
        displayName: values.name,
        email: values.email.trim(),
        password: values.password,
      }

      await handleSignup(userData, dispatch)
      // stop the loader
      actions.setSubmitting(false)
    },
  })

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack
            spacing={3}
            component={motion.div}
            initial={{ opacity: 0, y: 40 }}
            animate={style.fadeInUp.animate}
          >
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 30 }}
            animate={style.fadeInUp.animate}
          >
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ color: typography.colors.white }}
              loading={isSubmitting}
            >
              Sign up
            </LoadingButton>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  )
}

export default SignupForm
