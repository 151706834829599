import { Navigate, Route, Routes } from 'react-router-dom'
import routes from '@/pages/routes'
import ProtectedRoute from '@/components/routes/ProtectedRoute'
import Home from '@/pages/Home'
import Profile from '@/pages/Profile'
import ForgotPassword from '@/pages/auth/ForgotPassword'
import Login from '@/pages/auth/Login'
import Signup from '@/pages/auth/Signup'
import AddCollection from '@/pages/collections/AddCollection'
import Collections from '@/pages/collections/Collections'
import ViewCollection from '@/pages/collections/ViewCollection'
import Learn from '@/pages/learn/Learn'
import FlashCardOptions from '@/pages/learn/flashCards/FlashCardOptions'
import LearnAllMarkedToLearnWords from '@/pages/learn/flashCards/LearnAllMarkedToLearnWords'
import LearnSelectedCollection from '@/pages/learn/flashCards/LearnSelectedCollection'
import LearnWordsFromSelectedCollection from '@/pages/learn/flashCards/LearnWordsFromSelectedCollection'
import FaqPage from '@/pages/support/FaqPage'
import PrivacyPolicyPage from '@/pages/support/PrivacyPolicyPage'
import SupportPage from '@/pages/support/SupportPage'
import TermsAndConditionsPage from '@/pages/support/TermsAndConditionsPage'
import AddWord from '@/pages/words/AddWord'
import Words from '@/pages/words/Words'

const AppRoutes = () => {
  return (
    <Routes>
      {/* Open URLs */}
      <Route path={routes.home} element={<Home />} />
      <Route path={routes.signin} element={<Login />} />
      <Route path={routes.signup} element={<Signup />} />
      <Route path={routes.forgot} element={<ForgotPassword />} />
      <Route path={routes.faq} element={<FaqPage />} />
      <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
      <Route
        path={routes.termsAndConditions}
        element={<TermsAndConditionsPage />}
      />
      <Route path={routes.support} element={<SupportPage />} />
      <Route path={routes.reportBugs} element={<SupportPage bug={true} />} />
      {/* End open URLs */}
      {/* Protected URLs */}
      <Route
        path={routes.words}
        element={
          <ProtectedRoute>
            <Words />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.addWord}
        element={
          <ProtectedRoute>
            <AddWord />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.collections}
        element={
          <ProtectedRoute>
            <Collections />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.addCollection}
        element={
          <ProtectedRoute>
            <AddCollection />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.viewCollection}
        element={
          <ProtectedRoute>
            <ViewCollection />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.viewCollection}
        element={
          <ProtectedRoute>
            <ViewCollection />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.learn}
        element={
          <ProtectedRoute>
            <Learn />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.flashCardOptions}
        element={
          <ProtectedRoute>
            <FlashCardOptions />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.allMarkedToLearnWords}
        element={
          <ProtectedRoute>
            <LearnAllMarkedToLearnWords />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.wordsFromSelectedCollection}
        element={
          <ProtectedRoute>
            <LearnWordsFromSelectedCollection />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.learnSelectedCollection}
        element={
          <ProtectedRoute>
            <LearnSelectedCollection />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.profile}
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      {/* End protected URLs */}
      <Route path="*" element={<Navigate to={routes.home} />} />
    </Routes>
  )
}

export default AppRoutes
