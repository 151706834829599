import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Theme, styled } from '@mui/material/styles'
import { useAppDispatch } from '@hooks'
import { addWordToCollection } from '@reducers/collectionSlice'
import { updateCollectionInWords } from '@reducers/wordCollectionHandlers'
import { allWordsSelector } from '@/shared/redux/reducers/wordSlice'
import AppType from '@/types/AppType'
import { typography } from '@/styles/typography'

type WordsDialogProps = {
  isOpen: boolean
  onClose: () => void
  setCollection: (collection: AppType.Collection) => void
  collection: AppType.Collection
  wordsOfCollection: AppType.Word[]
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
}))

const WordsDialog: React.FC<WordsDialogProps> = (props: WordsDialogProps) => {
  const { isOpen, onClose, setCollection, collection, wordsOfCollection } =
    props
  const allWords: AppType.Word[] = useSelector(allWordsSelector)
  const [searchQuery, setSearchQuery] = useState('')

  const dispatch = useAppDispatch()

  const handleClose = () => {
    onClose()
  }

  const onSelectWord = (word: AppType.Word) => {
    const isDuplicate = wordsOfCollection.find((w) => w.id === word.id)

    if (!isDuplicate) {
      dispatch(addWordToCollection({ word, collection })).then(() => {
        // Update word list in the store with updated collection
        // so we added a new wordIds prop to collection
        dispatch(updateCollectionInWords(collection))
      })

      const updatedWordIds = { ...collection?.wordIds, [word.id as any]: true }
      const updatedCollection: AppType.Collection = {
        ...collection,
        wordIds: updatedWordIds,
      }
      setCollection(updatedCollection)

      toast.success('The word has been added to collection.')
      handleClose()
    } else {
      toast.warning('The word already has been added to collection.')
      return
    }
  }

  const handleSearch = (query: string) => {
    setSearchQuery(query)
  }

  const searchResult = () => {
    return allWords.filter(
      (word) =>
        word.original!.toLowerCase().includes(searchQuery.toLowerCase()) ||
        word.translation!.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }

  const filteredWords = searchQuery ? searchResult() : allWords

  return (
    <BootstrapDialog
      fullWidth
      onClose={handleClose}
      aria-labelledby="words-dialog"
      open={isOpen}
    >
      <DialogTitle sx={styles.dialogTitle} id="words-dialog">
        Select a word
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={styles.iconButton}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {allWords.length !== 0 && (
          <TextField
            label="Search"
            type="text"
            variant="outlined"
            sx={styles.search}
            fullWidth
            autoFocus
            placeholder="Find a word"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              endAdornment: searchQuery && (
                <IconButton onClick={() => handleSearch('')}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        )}

        {filteredWords.length !== 0 ? (
          filteredWords.map((word) => (
            <Card key={word.id} sx={styles.card}>
              <CardActionArea onClick={() => onSelectWord(word)}>
                <CardContent>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginBottom: 1 }}
                  >
                    <Stack direction="column" alignItems="start">
                      <Typography>original: {word.original}</Typography>
                      <Typography>translation: {word.translation}</Typography>
                      <Typography>from: {word.from}</Typography>
                      <Typography>to: {word.to}</Typography>
                    </Stack>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          ))
        ) : (
          <Typography variant="body2" color="text.secondary">
            There are no words
          </Typography>
        )}
      </DialogContent>
    </BootstrapDialog>
  )
}

const styles = {
  iconButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme: Theme) => theme.palette.grey[500],
  },

  dialogTitle: { m: 0, p: 2 },

  search: { mb: 1 },

  card: { mb: 1, backgroundColor: typography.colors.card },
}

export default WordsDialog
