import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Stack,
  Typography,
} from '@mui/material'
import { motion } from 'framer-motion'
import { sortByDateCollectionsSelector } from '@/shared/redux/reducers/collectionSlice'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import AppType from '@/types/AppType'
import CollectionCard from '@/components/collections/CollectionCard'
import Wrapper from '@/components/elements/Wrapper'
import AppBar from '@/components/elements/navigation/AppBar'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const LearnWordsFromSelectedCollection = () => {
  const sortedByDateCollections = useSelector(sortByDateCollectionsSelector)
  const [collections, setCollections] = useState<AppType.Collection[]>(
    sortedByDateCollections,
  )

  useEffect(() => {
    setCollections(sortedByDateCollections)
  }, [sortedByDateCollections])

  const onLearnCollection = (collection: AppType.Collection) => {
    history.navigate(
      routes.learnSelectedCollection.replace(':collectionId', collection.id!),
    )
  }

  return (
    <>
      <AppBar title="Flash Cards" linkBackTo={routes.flashCardOptions} />
      <Wrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 60 }}
        >
          <Typography variant="h5">
            Learn Words From Selected Collection
          </Typography>
        </Stack>
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 60 }}
          animate={style.fadeInUp.animate}
        >
          {collections && !collections.length ? (
            <>
              <style.Heading>
                <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                  You don't have any collections for learning.
                </Typography>
              </style.Heading>
              <Button
                sx={{ color: typography.colors.secondary }}
                fullWidth
                size="small"
                variant="outlined"
                style={style.button.bold}
                onClick={() => history.navigate(routes.words)}
              >
                Go to Words
              </Button>
            </>
          ) : (
            collections.map((collection) => (
              <Card key={collection.id} sx={{ mb: 1 }}>
                <CardActionArea onClick={() => onLearnCollection(collection)}>
                  <CollectionCard collection={collection} />
                </CardActionArea>
              </Card>
            ))
          )}
        </Box>
      </Wrapper>
    </>
  )
}

export default LearnWordsFromSelectedCollection
