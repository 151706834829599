import { Container, Link, Paper } from '@mui/material'
import Typography from '@mui/material/Typography'

const Footer = () => {
  return (
    <Paper
      sx={{
        backgroundColor: (theme) => theme.palette.secondary.main,
        p: 1,
        marginTop: 'calc(10% + 60px)',
        position: 'fixed',
        bottom: 0,
        width: '100%',
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Container maxWidth="sm">
        <Typography variant="body2" color="primary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://ronastudio.com/">
            Rona Learning Studio
          </Link>{' '}
          2020-{new Date().getFullYear()}, all rights reserved.
        </Typography>
      </Container>
    </Paper>
  )
}

export default Footer
