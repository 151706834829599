import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '@store'
import {
  WordAndCollectionState,
  removeWordFromCollection,
  updateCollection,
} from '@reducers/collectionSlice'
import { getWordsWithCollections } from '@utils/getWordsWithCollections'
import AppType from '@/types/AppType'

export const updateCollectionInWords = createAsyncThunk<
  AppType.Word[],
  AppType.Collection
>('updateCollectionInWords', async (collection, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState
    if (!state) return []

    const words = state.words
    const collections = state.collections

    const updatedCollections: AppType.Collection[] = {
      ...collections,
      ...collection,
    }

    const wordsWithCollections = words
      ? getWordsWithCollections(words, updatedCollections || [])
      : []

    return wordsWithCollections
  } catch (error) {
    throw error
  }
})

export const removeCollectionFromWord = createAsyncThunk<
  AppType.Word,
  WordAndCollectionState
>('removeCollectionFromWord', async ({ word, collection }) => {
  try {
    const updatedCollections = (word.collections || []).filter(
      (c) => c.id !== collection.id,
    )

    const updatedWord: AppType.Word = {
      ...word,
      collections: updatedCollections,
    }

    return updatedWord
  } catch (error) {
    throw error
  }
})

export const removeWordFromAllCollections = (
  dispatch: AppDispatch,
  word: AppType.Word,
) => {
  const collections = word.collections
  collections &&
    collections?.forEach((collection) => {
      const updatedCollection: AppType.Collection = {
        ...collection,
        wordIds: { ...collection?.wordIds },
      }

      if (updatedCollection.wordIds)
        delete updatedCollection.wordIds[`${word.id}`]

      dispatch(
        removeWordFromCollection({ word, collection: updatedCollection }),
      ).then(() => {
        // Update word list in the store with updated collection
        // so we added a new wordIds prop to collection
        dispatch(updateCollectionInWords(updatedCollection))
      })
    })
}

export const addWordToCollections = (
  dispatch: AppDispatch,
  word: AppType.Word,
) => {
  word.collections?.forEach((collection) => {
    dispatch(
      updateCollection({
        ...collection,
        wordIds: { ...collection.wordIds, [`${word.id}`]: true },
      }),
    ).then(() => {
      // Update word list in the store with updated collection
      // so we added a new wordIds prop to collection
      dispatch(updateCollectionInWords(collection))
    })
  })
}

export const wordCollectionHandlers = {
  updateCollectionInWords,
  removeCollectionFromWord,
  removeWordFromAllCollections,
  addWordToCollections,
}
