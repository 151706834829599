import { isValidIds } from '@utils/isValidIds'
import { validation } from '@utils/validation'
import AppType from '@/types/AppType'
import {
  getDatabase,
  ref,
  remove,
  serverTimestamp,
  set,
  update,
} from '@firebase/database'

const addCollection = async (
  collection: AppType.Collection,
  userId: string,
) => {
  try {
    const { id } = collection

    if (!isValidIds(id, userId))
      throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const collectionRef = ref(db, `users/${userId}/collections/${id}`)
    const updatedCollection = { ...collection, created: serverTimestamp() }

    await set(collectionRef, updatedCollection)
  } catch (error) {
    console.error('Collection addition error:', error)
    throw error
  }
}

const updateCollection = async (
  collection: AppType.Collection,
  userId: string,
) => {
  try {
    const { id } = collection

    if (!isValidIds(id, userId))
      throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const collectionRef = ref(db, `users/${userId}/collections/${id}`)

    await update(collectionRef, collection)
  } catch (error) {
    console.error('Collection updating error:', error)
    throw error
  }
}

const removeCollection = async (collectionId: string, userId: string) => {
  try {
    if (!isValidIds(collectionId, userId))
      throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const collectionRef = ref(db, `users/${userId}/collections/${collectionId}`)

    await remove(collectionRef)
  } catch (error) {
    console.error('Collection removing error:', error)
    throw error
  }
}

const addWordToCollection = async (
  collection: AppType.Collection,
  wordId: string,
  userId: string,
) => {
  try {
    const { id } = collection

    if (!isValidIds(id, wordId, userId))
      throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const collectionRef = ref(
      db,
      `users/${userId}/collections/${id}/wordIds/${wordId}`,
    )

    await set(collectionRef, true)
  } catch (error) {
    console.error('Add word to collection error:', error)
    throw error
  }
}

const removeWordFromCollection = async (
  collectionId: string,
  wordId: string,
  userId: string,
) => {
  try {
    if (!isValidIds(collectionId, wordId, userId))
      throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const collectionRef = ref(
      db,
      `users/${userId}/collections/${collectionId}/wordIds/${wordId}`,
    )

    await remove(collectionRef)
  } catch (error) {
    console.error('Remove word from collection error:', error)
    throw error
  }
}

export const collectionAPI = {
  addCollection,
  updateCollection,
  removeCollection,
  addWordToCollection,
  removeWordFromCollection,
}
