import { Link as RouterLink } from 'react-router-dom'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import routes from '@/pages/routes'
import HomeHeader from '@/components/elements/HomeHeader'
import Wrapper from '@/components/elements/Wrapper'
import Nav from '@/components/elements/navigation/Nav'
import Faq from '@/pages/support/components/Faq'

const Home = () => {
  return (
    <>
      <Nav />
      <HomeHeader />

      <Wrapper>
        <Typography paragraph variant="h6" align="center">
          Welcome to the Rona Learning Studio!
        </Typography>

        <Faq />

        <Typography paragraph variant="h6" align="center" sx={{ marginTop: 3 }}>
          Links:
        </Typography>

        <Box>
          <List component="nav">
            <Link
              component={RouterLink}
              to={routes.privacyPolicy}
              color="secondary"
              underline="none"
            >
              <ListItemButton>
                <ListItemText primary="Privacy Policy" />
              </ListItemButton>
            </Link>
            <Divider />

            <Link
              component={RouterLink}
              to={routes.termsAndConditions}
              color="secondary"
              underline="none"
            >
              <ListItemButton>
                <ListItemText primary="Terms and Conditions" />
              </ListItemButton>
            </Link>
            <Divider />

            <Link
              component={RouterLink}
              to={routes.faq}
              color="secondary"
              underline="none"
            >
              <ListItemButton>
                <ListItemText primary="FAQ" />
              </ListItemButton>
            </Link>
            <Divider />

            <Link
              component={RouterLink}
              to={routes.support}
              color="secondary"
              underline="none"
            >
              <ListItemButton>
                <ListItemText primary="Support" />
              </ListItemButton>
            </Link>
            <Divider />

            <Link
              component={RouterLink}
              to={routes.reportBugs}
              color="secondary"
              underline="none"
            >
              <ListItemButton>
                <ListItemText primary="Report Bugs" />
              </ListItemButton>
            </Link>
            <Divider />
          </List>
        </Box>
      </Wrapper>
    </>
  )
}

export default Home
