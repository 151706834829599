import {
  AuthCredential,
  FacebookAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getAuth,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
} from 'firebase/auth'

const addUserWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const auth = await getAuth()
    return await createUserWithEmailAndPassword(auth, email, password)
  } catch (error) {
    console.error(error)
    throw error
  }
}

const addUserWithSocPopup = async (authProviderType: string) => {
  try {
    const auth = await getAuth()
    const provider = (() => {
      switch (authProviderType) {
        case 'google':
          return new GoogleAuthProvider()
        case 'facebook':
          return new FacebookAuthProvider()
        default:
          throw new Error('Invalid authentication provider type')
      }
    })()

    return await signInWithPopup(auth, provider)
      .then((result) => {
        const details = getAdditionalUserInfo(result)
        const isNewUser = details?.isNewUser
        const user = result.user

        return { isNewUser, user }
      })
      .catch((error) => {
        throw new Error(error)
      })
  } catch (error) {
    console.error(error)
    throw error
  }
}

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const auth = await getAuth()
    return await signInWithEmailAndPassword(auth, email, password)
  } catch (error) {
    console.error(error)
    throw error
  }
}

const logInWithCredential = async (credential: AuthCredential) => {
  try {
    const auth = await getAuth()
    return await signInWithCredential(auth, credential)
  } catch (error) {
    console.error(error)
    throw error
  }
}

const passwordResetEmail = async (email: string) => {
  try {
    const auth = getAuth()
    await sendPasswordResetEmail(auth, email)
  } catch (error) {
    console.error(error)
    throw error
  }
}

const updateUserPassword = async (oldPassword: string, newPassword: string) => {
  try {
    const auth = getAuth()
    const user = auth.currentUser

    if (!user?.email) throw new Error()
    await signInWithEmailAndPassword(auth, user.email, oldPassword).then(() =>
      updatePassword(user, newPassword),
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}

const logOut = async () => {
  try {
    const auth = await getAuth()
    await signOut(auth)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const authAPI = {
  addUserWithEmailAndPassword,
  addUserWithSocPopup,
  logInWithEmailAndPassword,
  logInWithCredential,
  passwordResetEmail,
  updateUserPassword,
  logOut,
}
