import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectDatabaseEmulator, getDatabase } from '@firebase/database'

// the values to initialize the firebase app can be found in your firebase project
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

const initFirebase = async () => {
  try {
    const app = await initializeApp(firebaseConfig)
    // Initialize Realtime Database and get a reference to the service
    const db = await getDatabase(app)
    const auth = await getAuth(app)

    if (process.env.NODE_ENV !== 'production') {
      try {
        connectAuthEmulator(auth, 'http://127.0.0.1:9099')
        connectDatabaseEmulator(db, '127.0.0.1', 9000)
      } catch (error) {
        console.log(error)
        return error
      }
    }
  } catch (error) {
    console.error(error)
    return error
  }
}

export default initFirebase
