import { isValidIds } from '@utils/isValidIds'
import { validation } from '@utils/validation'
import AppType from '@/types/AppType'
import {
  getDatabase,
  ref,
  remove,
  serverTimestamp,
  set,
  update,
} from '@firebase/database'

const translateWord = async (word: string, language: AppType.Language) => {
  const url = `${process.env.REACT_APP_TRANSLATE_URL}/?key=${process.env.REACT_APP_API_KEY}`
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      q: word,
      source: language?.from?.value,
      target: language?.to?.value,
      format: 'text',
    }),
  }

  try {
    const response = await fetch(url, requestOptions)
    return response
  } catch (error) {
    console.error('Translation error:', error)
    throw error
  }
}

const addWord = async (word: AppType.Word, userId: string) => {
  try {
    if (!isValidIds(word.id, userId))
      throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const wordRef = ref(db, `users/${userId}/words/${word.id}`)
    const updatedWord = {
      ...word,
      created: serverTimestamp(),
    }

    await set(wordRef, updatedWord)
  } catch (error) {
    console.error('Word addition error:', error)
    throw error
  }
}

const updateWord = async (word: AppType.Word, userId: string) => {
  try {
    if (!isValidIds(word.id, userId))
      throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const wordRef = ref(db, `users/${userId}/words/${word.id}`)

    await update(wordRef, word)
  } catch (error) {
    console.error('Word updating error:', error)
    throw error
  }
}

const removeWord = async (wordId: string, userId: string) => {
  try {
    if (!isValidIds(wordId, userId))
      throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const wordRef = ref(db, `users/${userId}/words/${wordId}`)

    await remove(wordRef)
  } catch (error) {
    console.error('Word removing error:', error)
    throw error
  }
}

const setLearnStatus = async (word: AppType.Word, userId: string) => {
  try {
    if (!isValidIds(word.id, userId))
      throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const learnStatusRef = ref(
      db,
      `users/${userId}/words/${word.id}/isForLearn`,
    )
    const statusToSet = word?.isForLearn ? true : null

    await set(learnStatusRef, statusToSet)
  } catch (error) {
    console.error('Set learn status error:', error)
    throw error
  }
}

export const wordAPI = {
  translateWord,
  addWord,
  updateWord,
  removeWord,
  setLearnStatus,
}
