import { Link } from 'react-router-dom'
import { Box } from '@mui/material'

const Logo = () => {
  return (
    <Link to="/">
      <Box
        component="img"
        src="rona-learning-studio.png"
        alt="logo"
        sx={{ height: 50, mt: 1 }}
      />
    </Link>
  )
}

export default Logo
