import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import { motion } from 'framer-motion'
import routes from '@/pages/routes'
import Wrapper from '@/components/elements/Wrapper'
import AppBar from '@/components/elements/navigation/AppBar'
import AddWordForm from '@/components/words/AddWordForm'
import { style } from '@/styles/global'

const AddWord = () => {
  const { state } = useLocation()
  const [word] = useState(state?.word)

  return (
    <>
      <AppBar title="Words" linkBackTo={routes.words} />

      <Wrapper>
        <style.Content>
          <style.Heading component={motion.div} {...style.fadeInUp}>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              {word ? 'Edit' : 'Add'} Word
            </Typography>
          </style.Heading>
          <AddWordForm word={word} />
        </style.Content>
      </Wrapper>
    </>
  )
}

export default AddWord
