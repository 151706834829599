import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { School } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { useAppDispatch } from '@hooks'
import {
  addWordToCollections,
  removeWordFromAllCollections,
} from '@reducers/wordCollectionHandlers'
import {
  removeWord,
  setLearnStatus,
  sortByDateWordsSelector,
} from '@reducers/wordSlice'
import { history } from '@utils/history'
import { validation } from '@utils/validation'
import routes from '@/pages/routes'
import AppType from '@/types/AppType'
import ContextMenu from '@/components/elements/ContextMenu'
import ListenButton from '@/components/elements/ListenButton'
import Wrapper from '@/components/elements/Wrapper'
import Nav from '@/components/elements/navigation/Nav'
import CollectionsDialog from '@/components/words/CollectionsDialog'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const Words = () => {
  const dispatch = useAppDispatch()
  const sortedByDateWords = useSelector(sortByDateWordsSelector)
  const [words, setWords] = useState<AppType.Word[]>(sortedByDateWords)
  const [selectedWord, setSelectedWord] = useState<AppType.Word | null>(null)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    setWords(sortedByDateWords)
  }, [sortedByDateWords])

  const onEditWord = (word: AppType.Word) => {
    // send a parameter with navigation
    history.navigate(routes.addWord, { state: { word } })
  }

  const onRemoveWord = async (word: AppType.Word) => {
    await dispatch(removeWord(word))
      .then(() => {
        removeWordFromAllCollections(dispatch, word)
        toast.success('The word has been removed.')
      })
      .catch((error) => {
        toast.error(validation.errorMessages.somethingWrong)
        throw new Error(error)
      })
  }

  const onMoveWordToFolder = (word: AppType.Word) => {
    setSelectedWord(word)
  }

  const handleCloseCollectionsDialog = () => {
    setSelectedWord(null)
  }

  const handleAddWordToCollection = (
    isCollectionsUpdated: boolean,
    updatedCollections: AppType.Collection[],
    word: AppType.Word,
  ) => {
    if (isCollectionsUpdated) {
      const updatedWord = { ...word, collections: updatedCollections }
      addWordToCollections(dispatch, updatedWord)
    }
  }

  const handleSetWordLearnStatus = (word: AppType.Word) => {
    const updatedWord: AppType.Word = { ...word, isForLearn: !word.isForLearn }
    dispatch(setLearnStatus(updatedWord))
  }

  const handleSearch = (query: string) => {
    setSearchQuery(query)
  }

  const searchResult = () => {
    return words.filter(
      (word) =>
        word.original!.toLowerCase().includes(searchQuery.toLowerCase()) ||
        word.translation!.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }

  const filteredWords = searchQuery ? searchResult() : words

  return (
    <>
      <Nav />
      <Wrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 60 }}
        >
          <Typography variant="h5">Words</Typography>
          <Button
            sx={{ color: typography.colors.secondary }}
            size="small"
            variant="outlined"
            style={style.button.bold}
            onClick={() => history.navigate(routes.addWord)}
          >
            Add Word
          </Button>
        </Stack>

        {sortedByDateWords.length !== 0 && (
          <TextField
            label="Search"
            type="text"
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            autoFocus
            placeholder="Find a word"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              endAdornment: searchQuery && (
                <IconButton onClick={() => handleSearch('')}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        )}

        {filteredWords.length !== 0 ? (
          filteredWords.map((word) => (
            <Card key={word.id} sx={{ mb: 1 }}>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ marginBottom: 1 }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      height={100}
                    >
                      <Tooltip
                        title={`Select${word.isForLearn ? 'ed' : ''} for learn`}
                      >
                        <IconButton
                          onClick={() => handleSetWordLearnStatus(word)}
                        >
                          <School
                            sx={{
                              color: word.isForLearn
                                ? typography.colors.green
                                : null,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <ListenButton
                        word={word.translation!}
                        language={word.to!}
                      />
                    </Stack>
                    <Stack
                      direction="column"
                      alignItems="start"
                      sx={{ marginLeft: 2 }}
                    >
                      <Typography>original: {word.original}</Typography>
                      <Typography>translation: {word.translation}</Typography>
                      <Typography>from: {word.from}</Typography>
                      <Typography>to: {word.to}</Typography>
                      <Typography>comment: {word.comment}</Typography>
                      {word.collections && word.collections.length > 0 && (
                        <Typography>
                          collections:{' '}
                          {word.collections
                            .map((collection) => collection.name)
                            .join(', ')}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  <ContextMenu
                    onEdit={() => onEditWord(word)}
                    onRemove={async () => await onRemoveWord(word)}
                    onMove={() => onMoveWordToFolder(word)}
                  />
                </Stack>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography variant="body2" color="text.secondary">
            There are no words
          </Typography>
        )}

        {!!selectedWord ? (
          <CollectionsDialog
            isOpen={!!selectedWord}
            onClose={handleCloseCollectionsDialog}
            word={selectedWord}
            onAddWordToCollection={(isCollectionsUpdated, updatedCollections) =>
              handleAddWordToCollection(
                isCollectionsUpdated,
                updatedCollections,
                selectedWord,
              )
            }
          />
        ) : null}
      </Wrapper>
    </>
  )
}

export default Words
