import { FC, useState } from 'react'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'

type ContextMenuProps = {
  onEdit: () => void
  onRemove: () => void
  onMove?: () => void
  onDetails?: () => void
}

const ContextMenu: FC<ContextMenuProps> = (props) => {
  const { onEdit, onRemove, onMove, onDetails } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleOpenContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseContextMenu = () => {
    setAnchorEl(null)
  }

  const [isConfirmationDialogOpen, setDialogOpen] = useState(false)
  const openConfirmationDialog = () => {
    setDialogOpen(true)
  }

  const closeConfirmationDialog = () => {
    setDialogOpen(false)
  }

  const handleConfirm = () => {
    onRemove()
    closeConfirmationDialog()
    handleCloseContextMenu()
  }

  const handleOnMove = () => {
    onMove && onMove()
    handleCloseContextMenu()
  }

  const handleOnDetails = () => {
    onDetails && onDetails()
    handleCloseContextMenu()
  }

  return (
    <>
      <IconButton onClick={handleOpenContextMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseContextMenu}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {onDetails && (
          <MenuItem onClick={handleOnDetails}>
            <InfoOutlinedIcon />
            &nbsp;&nbsp;Open
          </MenuItem>
        )}
        {onMove && (
          <MenuItem onClick={handleOnMove}>
            <CreateNewFolderOutlinedIcon />
            &nbsp;&nbsp;Collections
          </MenuItem>
        )}
        <MenuItem onClick={onEdit}>
          <ModeEditOutlineOutlinedIcon />
          &nbsp;&nbsp;Edit
        </MenuItem>
        <MenuItem onClick={openConfirmationDialog}>
          <DeleteOutlineOutlinedIcon />
          &nbsp;&nbsp;Remove
        </MenuItem>
        <MenuItem onClick={handleCloseContextMenu}>
          <ClearOutlinedIcon />
          &nbsp;&nbsp;Cancel
        </MenuItem>
      </Menu>

      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={closeConfirmationDialog}
        onConfirm={handleConfirm}
        title="Delete"
        message="Are you sure you want to permanently remove it?"
        confirmLabel="Delete"
        isRedButton={true}
      />
    </>
  )
}

export default ContextMenu
