import React from 'react'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'

const PricingBox = styled(Box)({
  border: '1px solid #ddd',
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
  boxShadow: '0 1px 1px rgba(0, 0, 0, .05)',
})

const pricingData = [
  {
    type: 'Free',
    price: '$0',
    period: '/mo',
    users: '10 users included',
    storage: '2 GB of storage',
    support: ['Help center access', 'Email support'],
  },
  {
    type: 'Pro',
    price: '$15',
    period: '/mo',
    users: '20 users included',
    storage: '10 GB of storage',
    support: ['Help center access', 'Priority email support'],
  },
  {
    type: 'Enterprise',
    price: '$30',
    period: '/mo',
    users: '50 users included',
    storage: '30 GB of storage',
    support: ['Help center access', 'Phone & email support'],
  },
]

const Pricing: React.FC = () => {
  return (
    <Container>
      <Grid container spacing={3}>
        {pricingData.map((pricing, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <PricingBox>
              <Typography variant="h5">{pricing.type}</Typography>
              <Typography variant="h3">{pricing.price}</Typography>
              <Typography variant="subtitle1">{pricing.period}</Typography>
              <Typography variant="body1">{pricing.users}</Typography>
              <Typography variant="body1">{pricing.storage}</Typography>
              {pricing.support.map((item, idx) => (
                <Typography variant="body2" key={idx}>
                  {item}
                </Typography>
              ))}
              <Button variant="contained" color="primary">
                Select Plan
              </Button>
            </PricingBox>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Pricing
