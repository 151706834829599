import AppType from '@/types/AppType'
import { userAPI } from '@/db/userAPI'

const getCurrentUser = async () => {
  return await userAPI.getCurrentUser()
}

const initUserData = async (userId: string) => {
  return await userAPI.initUserData(userId)
}

const addUserToDB = async (user: AppType.User) => {
  return await userAPI.addUserToDB(user)
}

const updateUserName = async (userId: string, name: string) => {
  return await userAPI.updateUserName(userId, name)
}

const deleteAccount = async (userId: string) => {
  return await userAPI.deleteAccount(userId)
}

export const userService = {
  getCurrentUser,
  initUserData,
  addUserToDB,
  updateUserName,
  deleteAccount,
}
