import { useState } from 'react'
import LoginIcon from '@mui/icons-material/Login'
import MenuIcon from '@mui/icons-material/Menu'
import { Button, IconButton, Stack } from '@mui/material'
import { useAppSelector } from '@hooks'
import { isUserAuthenticatedSelector } from '@reducers/authSlice'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import AppBar from '@/components/elements/navigation/AppBar'
import AppMenu from '@/components/elements/navigation/AppMenu'
import MobileMenu from '@/components/elements/navigation/MobileMenu'
import { style } from '@/styles/global'

const Nav = () => {
  const authenticated = useAppSelector(isUserAuthenticatedSelector)
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  return (
    <>
      <AppBar
        title="Rona Learning Studio"
        actions={
          <>
            {authenticated ? (
              <>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ display: { xs: 'none', sm: 'inherit' } }}
                >
                  <AppMenu />
                </Stack>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ display: { sm: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  style={style.button.bold}
                  sx={{ display: { xs: 'none', sm: 'inherit' } }}
                  onClick={() => history.navigate(routes.signin)}
                >
                  Sign In
                </Button>
                <IconButton
                  color="primary"
                  size="small"
                  sx={{ display: { sm: 'none' } }}
                  onClick={() => history.navigate(routes.signin)}
                >
                  <LoginIcon />
                </IconButton>
              </>
            )}
          </>
        }
      />
      <MobileMenu
        isMobileMenuOpen={mobileOpen}
        handleCloseDrawer={handleDrawerToggle}
      />
    </>
  )
}

export default Nav
