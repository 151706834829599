import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Button, Card, Stack, Typography } from '@mui/material'
import {
  removeCollection,
  sortByDateCollectionsSelector,
} from '@reducers/collectionSlice'
import { useAppDispatch } from '@/shared/redux/hooks'
import { updateCollectionInWords } from '@/shared/redux/reducers/wordCollectionHandlers'
import { validation } from '@/shared/utils/validation'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import AppType from '@/types/AppType'
import CollectionCard from '@/components/collections/CollectionCard'
import ContextMenu from '@/components/elements/ContextMenu'
import Wrapper from '@/components/elements/Wrapper'
import Nav from '@/components/elements/navigation/Nav'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const Collections = () => {
  const dispatch = useAppDispatch()
  const sortedByDateCollections = useSelector(sortByDateCollectionsSelector)
  const [collections, setCollections] = useState<AppType.Collection[]>(
    sortedByDateCollections,
  )

  useEffect(() => {
    setCollections(sortedByDateCollections)
  }, [sortedByDateCollections])

  const onEditCollection = (collection: AppType.Collection) => {
    // send a parameter with navigation
    history.navigate(routes.addCollection, { state: { collection } })
  }

  const onRemoveCollection = async (collection: AppType.Collection) => {
    dispatch(removeCollection(collection))
      .then(() => {
        // Check if wordIds is not empty, update words list
        if (collection.wordIds && Object.keys(collection.wordIds).length > 0) {
          dispatch(updateCollectionInWords(collection))
        }
        toast.success('The collection has been removed.')
      })
      .catch((error) => {
        toast.error(validation.errorMessages.somethingWrong)
        throw new Error(error)
      })
  }

  const onViewCollection = (collection: AppType.Collection) => {
    history.navigate(
      routes.viewCollection.replace(':collectionId', collection.id!),
    )
  }

  return (
    <>
      <Nav />
      <Wrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 60 }}
        >
          <Typography variant="h5">Collections</Typography>
          <Button
            sx={{ color: typography.colors.secondary }}
            size="small"
            variant="outlined"
            style={style.button.bold}
            onClick={() => history.navigate(routes.addCollection)}
          >
            Add Collection
          </Button>
        </Stack>

        {collections?.length !== 0 ? (
          collections.map((collection) => (
            <Card key={collection.id} sx={{ mb: 1 }}>
              <CollectionCard collection={collection}>
                <ContextMenu
                  onDetails={() => onViewCollection(collection)}
                  onEdit={() => onEditCollection(collection)}
                  onRemove={async () => await onRemoveCollection(collection)}
                />
              </CollectionCard>
            </Card>
          ))
        ) : (
          <Typography variant="body2" color="text.secondary">
            There are no collections
          </Typography>
        )}
      </Wrapper>
    </>
  )
}

export default Collections
