import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Wrapper from '@/components/elements/Wrapper'
import Nav from '@/components/elements/navigation/Nav'
import SupportForm from '@/pages/support/components/SupportForm'

type SupportPageProps = {
  bug?: boolean
}

const SupportPage: React.FC<SupportPageProps> = ({ bug = false }) => {
  const email = 'support@ronastudio.com'

  return (
    <>
      <Nav />
      <Wrapper>
        <Typography variant="h4" gutterBottom>
          Contact Support
        </Typography>
        {bug && (
          <Typography gutterBottom mb={3}>
            Found a bug in one of our apps? Please let us know so we can help
            fix it.
          </Typography>
        )}

        <Typography gutterBottom mb={3}>
          Please email us at <Link href={`mailto:${email}`}>{email}</Link> the
          form below.
        </Typography>

        <SupportForm />
        <br />
      </Wrapper>
    </>
  )
}

export default SupportPage
