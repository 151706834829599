import AppType from '@/types/AppType'

/* Filter words of a collection  */
export const getWordsOfCollection = (
  collection: AppType.Collection,
  words: AppType.Word[],
): AppType.Word[] => {
  const wordIds = collection?.wordIds ? Object.keys(collection.wordIds) : []

  return words.filter(({ id }) => id && wordIds.includes(id))
}
