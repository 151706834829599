import AppType from '@/types/AppType'
import { collectionAPI } from '@/db/collectionAPI'

const addCollection = async (
  collection: AppType.Collection,
  userId: string,
) => {
  return await collectionAPI.addCollection(collection, userId)
}

const updateCollection = async (
  collection: AppType.Collection,
  userId: string,
) => {
  return await collectionAPI.updateCollection(collection, userId)
}

const removeCollection = async (collectionId: string, userId: string) => {
  return await collectionAPI.removeCollection(collectionId, userId)
}

const addWordToCollection = async (
  collection: AppType.Collection,
  wordId: string,
  userId: string,
) => {
  return await collectionAPI.addWordToCollection(collection, wordId, userId)
}

const removeWordFromCollection = async (
  collectionId: string,
  wordId: string,
  userId: string,
) => {
  return await collectionAPI.removeWordFromCollection(
    collectionId,
    wordId,
    userId,
  )
}

export const collectionService = {
  addCollection,
  updateCollection,
  removeCollection,
  addWordToCollection,
  removeWordFromCollection,
}
