import { typography } from './typography'
import { Box } from '@mui/material'
import styled from '@emotion/styled'

const easing = [0.6, -0.05, 0.01, 0.99]

const Heading = styled(Box)({
  textAlign: 'center',
})

const Content = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
})

const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
}

const button = {
  bold: {
    border: '2px solid',
  },
}

const submenu = {
  main: {
    mt: '50px',
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  button: {
    border: '2px solid',
    backgroundColor: typography.colors.card,
    '&:hover': {
      border: '2px solid',
      backgroundColor: typography.colors.white,
    },
  },
}

const style = {
  Heading,
  Content,
  fadeInUp,
  button,
  submenu,
}

export { style }
