import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@store'
import { logout } from '@reducers/authSlice'
import { initUserData } from '@reducers/user/initUserData'
import { validation } from '@utils/validation'
import { languageService } from '@services/languageService'
import AppType from '@/types/AppType'

type LanguageState = AppType.CommonState & AppType.Language

export const initializeLanguage = (
  language?: AppType.Language,
): AppType.Language => {
  return language
    ? {
        from: {
          value: language.from?.value || '',
          label: language.from?.label || '',
        },
        to: {
          value: language.to?.value || '',
          label: language.to?.label || '',
        },
      }
    : {}
}

const initialState: LanguageState = initializeLanguage()

export const setLanguage = createAsyncThunk<LanguageState, AppType.Language>(
  'setLanguage',
  async (language, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState
      const userId = state?.user.id
      if (!userId) throw new Error(validation.errorMessages.invalidId)

      const { from, to } = language
      const updatedLanguage: AppType.Language = {
        from: {
          value: from!.value,
          label: from!.label,
        },
        to: {
          value: to!.value,
          label: to!.label,
        },
      }

      await languageService
        .addLanguageToDB(updatedLanguage, userId)
        .catch((error) => {
          throw error
        })

      return thunkAPI.fulfillWithValue(updatedLanguage)
    } catch (error) {
      throw error
    }
  },
)

// Slice
export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // initUserData language
    builder.addCase(initUserData.fulfilled, (state, action) => {
      const { from, to } = action.payload.language
      state.from = from
      state.to = to
      state.error = initialState.error
    })
    builder.addCase(initUserData.rejected, () => {})

    // setLanguage
    builder.addCase(setLanguage.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
    builder.addCase(setLanguage.rejected, (state, action) => {
      Object.assign(state, initialState)
      state.error = action.error
    })

    // logout
    builder.addCase(logout.fulfilled, () => {})
  },
})

// Selectors
export const languageSelector = (state: RootState) =>
  state ? state.language : {}

export const getLanguage = createSelector(
  languageSelector,
  (language) => language,
)

export default languageSlice.reducer
