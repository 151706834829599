import AppType from '@/types/AppType'

/* Filter collections for a word */
export const getCollectionsForWord = (
  word: AppType.Word,
  collections: AppType.Collection[],
): AppType.Collection[] =>
  collections
    ? Object.values(collections).filter(
        (collection: AppType.Collection) =>
          word.id && collection.wordIds?.hasOwnProperty(word.id),
      )
    : []
