import { FC } from 'react'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import IconButton from '@mui/material/IconButton'
import { LANGUAGES } from '@/shared/utils/languages'

type ListenButtonProps = {
  word: string
  language: string
}

const ListenButton: FC<ListenButtonProps> = (props) => {
  const { word, language } = props
  const languageValue = LANGUAGES.find((l) => l.label === language)?.value

  const handleOnSpeak = () => {
    const msg = new SpeechSynthesisUtterance()
    msg.text = word
    msg.lang = languageValue!
    msg.pitch = 0.8
    speechSynthesis.speak(msg)
  }

  return (
    <IconButton onClick={handleOnSpeak}>
      <RecordVoiceOverIcon />
    </IconButton>
  )
}

export default ListenButton
