import { toast } from 'react-toastify'
import * as Yup from 'yup'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Provide a valid email address')
    .required('Email is required'),
  password: Yup.string().trim().required('Password is required'),
})

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Name is required'),
  email: Yup.string()
    .trim()
    .email('Email must be a valid email address')
    .required('Email is required'),
  password: Yup.string()
    .trim()
    .min(6, 'Password must be at least 6 characters')
    .max(24, 'Too Long!')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]/,
      'Invalid password format',
    )
    .required('Password is required'),
})

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Provide a valid email address')
    .required('Email is required'),
})

const PasswordUpdateSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .max(24, 'Too Long!')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]/,
      'Invalid password format',
    )
    .required('New Password is required'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Repeat Password is required'),
})

const FullNameSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Full Name is required'),
})

const WordSchema = Yup.object().shape({
  original: Yup.string()
    .trim()
    .max(50, 'Too Long!')
    .required('The original word is required'),
  translation: Yup.string()
    .trim()
    .max(50, 'Too Long!')
    .required('The translation word is required'),
  from: Yup.string().trim().required('The from is required'),
  to: Yup.string()
    .trim()
    .required('The to is required')
    .test(
      'not-equal',
      'To and From languages should not be equal',
      function (value) {
        const { from } = this.parent // Access the value of the 'from' field
        return value !== from
      },
    ),
  comment: Yup.string().trim().min(2, 'Too Short!').max(50, 'Too Long!'),
})

const CollectionSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('The collection name is required'),
})

const SupportSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('The name is required'),
  email: Yup.string()
    .trim()
    .email('Provide a valid email address')
    .required('Email is required'),
  message: Yup.string()
    .trim()
    .min(3, 'Too Short!')
    .max(9000, 'Too Long!')
    .required('The message is required'),
})

/* Messages */
const forgotPasswordMessages = (error: string) => {
  switch (error) {
    case 'auth/user-not-found':
      toast.error('The user not found')
      break
    case 'auth/invalid-email':
      toast.error('The email address is not valid.')
      break
    case 'auth/operation-not-allowed':
      toast.error('Operation not allowed.')
      break
    default:
      toast.error('Forgot password process failed')
      break
  }
}

const loginMessages = (error: string) => {
  switch (error) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      toast.error('Invalid email address and/or password')
      break
    case 'auth/too-many-requests':
      toast.error(
        'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
      )
      break
    default:
      toast.error('Login process failed')
      break
  }
}

const registerMessages = (error: string) => {
  switch (error) {
    case 'auth/email-already-in-use':
      toast.error('The email address is already in use')
      break
    case 'auth/invalid-email':
      toast.error('The email address is not valid.')
      break
    case 'auth/operation-not-allowed':
      toast.error('Operation not allowed.')
      break
    case 'auth/weak-password':
      toast.error('The password is too weak.')
      break
    default:
      toast.error('Authentication failed')
      break
  }
}

const socialMessages = (error: string) => {
  switch (error) {
    case 'auth/popup-closed-by-user':
      toast.error('Login popup has closed by user.')
      break
    default:
      toast.error('Social authentication failed')
      break
  }
}

const collectionMessages = (error: string) => {
  toast.error(`collection: Some error happens. ${error}`)
}

const wordMessages = (error: string) => {
  toast.error(`word: Some error happens. ${error}`)
}

const errorMessages = {
  invalidId: 'Invalid id parameter',
  somethingWrong:
    'Something went wrong. Please contact the administrator for assistance.',
  accountDeleted:
    'Your account has been deleted. If you want to restore it, please text the administrator. Thanks!',
}

const validation = {
  LoginSchema,
  SignupSchema,
  ForgotPasswordSchema,
  PasswordUpdateSchema,
  FullNameSchema,
  WordSchema,
  CollectionSchema,
  SupportSchema,
  forgotPasswordMessages,
  loginMessages,
  registerMessages,
  socialMessages,
  collectionMessages,
  wordMessages,
  errorMessages,
}

export { validation }
