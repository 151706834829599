import { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

type AlertDialogButtonProps = {
  buttonLabel: string
  buttonColor: string
  title: string
  message: string
  handleConfirm: () => void
  cancelLabel?: string
  confirmLabel?: string
  isConfirmButtonRed?: boolean
}

const AlertDialogButton: React.FC<AlertDialogButtonProps> = (props) => {
  const {
    buttonLabel = 'Open Confirmation Dialog',
    buttonColor = typography.colors.green,
    title = 'Confirmation',
    message = 'Are you sure you want to perform this action?',
    handleConfirm,
    cancelLabel = 'Cancel',
    confirmLabel = 'Confirm',
    isConfirmButtonRed = false,
  } = props

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirmation = () => {
    handleConfirm()
    setOpen(false)
  }

  return (
    <>
      <Button
        sx={{ mt: 3, color: buttonColor }}
        fullWidth
        variant="outlined"
        style={style.button.bold}
        onClick={handleOpen}
      >
        {buttonLabel}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelLabel}
          </Button>
          <Button
            color={isConfirmButtonRed ? 'error' : 'primary'}
            sx={{ m: 1 }}
            variant="contained"
            onClick={handleConfirmation}
          >
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AlertDialogButton
