import { Box, Divider, Drawer, Stack } from '@mui/material'
import AppMenu from '@/components/elements/navigation/AppMenu'
import Logo from '@/components/elements/navigation/Logo'
import { typography } from '@/styles/typography'

type MobileMenuProps = {
  isMobileMenuOpen: boolean
  handleCloseDrawer: () => void
  window?: () => Window
}

const MobileMenu = (props: MobileMenuProps) => {
  const drawerWidth = 240
  const { isMobileMenuOpen, handleCloseDrawer, window } = props
  const container =
    window !== undefined ? () => window().document.body : undefined

  const drawer = (
    <Box
      onClick={handleCloseDrawer}
      sx={{
        textAlign: 'center',
        backgroundColor: typography.colors.card,
        height: '100%',
      }}
    >
      <Logo />
      <Divider />
      <Stack direction="column" spacing={2} sx={{ m: 2 }}>
        <AppMenu />
      </Stack>
    </Box>
  )

  return (
    <nav>
      <Drawer
        container={container}
        variant="temporary"
        open={isMobileMenuOpen}
        onClose={handleCloseDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  )
}

export default MobileMenu
