// fonts - material ui was built with roboto in mind
import 'typeface-roboto'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
// some global css
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { persistor, store } from '@store'
import '@/styles/index.css'
import initFirebase from '@/db/initFirebase'
import App from '@/pages/App'
import * as serviceWorkerRegistration from '@/serviceWorkerRegistration'
// material ui theme
import theme from '@/theme'

initFirebase()

const root = createRoot(document.getElementById('root')!)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ToastContainer />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
)

serviceWorkerRegistration.register()
