import { toast } from 'react-toastify'
import { Box, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useAppDispatch } from '@/shared/redux/hooks'
import { logout } from '@/shared/redux/reducers/authSlice'
import { deleteAccount } from '@/shared/redux/reducers/user/userSlice'
import { validation } from '@/shared/utils/validation'
import AlertDialogButton from '@/components/dialogs/AlertDialogButton'
import Wrapper from '@/components/elements/Wrapper'
import Nav from '@/components/elements/navigation/Nav'
import AccountDetailsForm from '@/components/profile/AccountDetailsForm'
import PasswordUpdateForm from '@/components/profile/PasswordUpdateForm'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const Profile = () => {
  const dispatch = useAppDispatch()

  const handleSignOut = async () => {
    try {
      await dispatch(logout())
    } catch (error) {
      toast.error(validation.errorMessages.somethingWrong)
      console.error('Error signing out:', error)
    }
  }

  const handleDeleteAccount = async () => {
    try {
      await dispatch(deleteAccount()).then(() => {
        dispatch(logout())
        toast.success('Your account has been deleted.')
      })
    } catch (error) {
      toast.error(validation.errorMessages.somethingWrong)
      console.error('Error deleting account:', error)
    }
  }

  return (
    <>
      <Nav />
      <Wrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 60 }}
        >
          <Typography variant="h5">Profile</Typography>
        </Stack>
        <style.Content>
          <style.Heading component={motion.div} {...style.fadeInUp}>
            <Typography sx={{ color: 'text.secondary', mb: 3 }}>
              Account Details
            </Typography>
          </style.Heading>
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={style.fadeInUp.animate}
          >
            <AccountDetailsForm />

            <style.Heading>
              <Typography sx={{ color: 'text.secondary', mt: 3, mb: 3 }}>
                Update Password
              </Typography>
            </style.Heading>

            <PasswordUpdateForm />

            <style.Heading>
              <Typography sx={{ color: 'text.secondary', mt: 4, mb: 2 }}>
                Options
              </Typography>
            </style.Heading>

            <AlertDialogButton
              buttonLabel="Sign Out"
              buttonColor={typography.colors.primary}
              title="Sign Out"
              message="Are you sure you want to sign out?"
              confirmLabel="Yes"
              handleConfirm={handleSignOut}
              isConfirmButtonRed={true}
            />

            <AlertDialogButton
              buttonLabel="Delete Account"
              buttonColor={typography.colors.red}
              title="Delete account"
              message="Are you sure you want to permanently delete your account?"
              confirmLabel="Delete"
              handleConfirm={handleDeleteAccount}
              isConfirmButtonRed={true}
            />
          </Box>
        </style.Content>
      </Wrapper>
    </>
  )
}

export default Profile
