import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Button, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { collectionByIdSelector } from '@/shared/redux/reducers/collectionSlice'
import { allWordsSelector } from '@/shared/redux/reducers/wordSlice'
import { getWordsOfCollection } from '@/shared/utils/getWordsOfCollection'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import AppType from '@/types/AppType'
import Wrapper from '@/components/elements/Wrapper'
import AppBar from '@/components/elements/navigation/AppBar'
import FlashCard from '@/components/learn/FlashCard'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const LearnSelectedCollection = () => {
  const { collectionId } = useParams()
  const [collection] = useState<AppType.Collection>(
    useSelector(collectionByIdSelector(collectionId!)) as AppType.Collection,
  )
  const allWords = useSelector(allWordsSelector)
  const wordsOfCollection: AppType.Word[] = getWordsOfCollection(
    collection,
    allWords,
  )

  return (
    <>
      <AppBar
        title="Collections for learn"
        linkBackTo={routes.wordsFromSelectedCollection}
      />
      <Wrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 60 }}
        >
          <Typography variant="h5">
            Words From "{collection.name}" collection
          </Typography>
        </Stack>
        <style.Content>
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={style.fadeInUp.animate}
          >
            {wordsOfCollection && !wordsOfCollection.length ? (
              <>
                <style.Heading>
                  <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                    The "{collection?.name}" collection doesn't have any words
                    yet.{'\n\n'}To have an opportunity to learn words from this
                    collection, you need to add words to it.
                  </Typography>
                </style.Heading>
                <Button
                  sx={{ color: typography.colors.secondary }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  style={style.button.bold}
                  onClick={() => history.navigate(routes.words)}
                >
                  Go to Words
                </Button>
              </>
            ) : (
              <FlashCard words={wordsOfCollection} />
            )}
          </Box>
        </style.Content>
      </Wrapper>
    </>
  )
}

export default LearnSelectedCollection
