import { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Divider, Link, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { isUserAuthenticatedSelector } from '@reducers/authSlice'
import { useAppSelector } from '@/shared/redux/hooks'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import LoginForm from '@/components/auth/LoginForm'
import SocialAuth from '@/components/auth/SocialAuth'
import Wrapper from '@/components/elements/Wrapper'
import Logo from '@/components/elements/auth/Logo'
import AppBar from '@/components/elements/navigation/AppBar'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const Login = () => {
  const authenticated = useAppSelector(isUserAuthenticatedSelector)

  useEffect(() => {
    // redirect to home if already logged in
    if (authenticated) {
      history.navigate(routes.home)
    }
  }, [authenticated])

  return (
    <>
      <AppBar title="Home" linkBackTo={routes.home} />
      <Wrapper>
        <style.Content>
          <style.Heading component={motion.div} {...style.fadeInUp}>
            <Logo />
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              Login to your account
            </Typography>
          </style.Heading>

          <Box component={motion.div} {...style.fadeInUp}>
            <SocialAuth />
          </Box>

          <Divider sx={{ my: 3 }} component={motion.div} {...style.fadeInUp}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              OR
            </Typography>
          </Divider>

          <LoginForm />

          <Typography
            component={motion.p}
            {...style.fadeInUp}
            variant="body2"
            align="center"
            sx={{ mt: 3 }}
          >
            Don't have an account?{' '}
            <Link
              variant="subtitle2"
              color={typography.colors.blue}
              underline="hover"
              component={RouterLink}
              to={routes.signup}
            >
              Sign up
            </Link>
          </Typography>
        </style.Content>
      </Wrapper>
    </>
  )
}

export default Login
