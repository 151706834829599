import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Theme, styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AppType from '@/types/AppType'
import {
  allCollectionsSelector,
  updateCollection,
} from '@reducers/collectionSlice'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { typography } from '@/styles/typography'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import { wordCollectionHandlers } from '@reducers/wordCollectionHandlers'
import { useAppDispatch } from '@hooks'
import { isObjectsEqual } from '@utils/compareObjects'

type CollectionsDialogProps = {
  isOpen: boolean
  onClose: () => void
  onAddWordToCollection: (
    isCollectionsUpdated: boolean,
    collections: AppType.Collection[],
  ) => void
  word?: AppType.Word
}

type SectionProps = {
  collections: AppType.Collection[]
  isAllCollection: boolean
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const CollectionsDialog: React.FC<CollectionsDialogProps> = (
  props: CollectionsDialogProps,
) => {
  const { word, isOpen, onClose, onAddWordToCollection } = props
  const dispatch = useAppDispatch()
  const wordCollections = word?.collections || []
  const allCollections: AppType.Collection[] = useSelector(
    allCollectionsSelector,
  )
  const [_collections, _setCollections] = useState(wordCollections)
  const [_allCollections, _setAllCollections] = useState(
    allCollections.filter((c) => !wordCollections.some((wc) => c.id === wc.id)),
  )

  const handleClose = () => {
    onClose()
  }

  const handleSave = () => {
    if (word && word.collections?.length !== 0) {
      const deletedCollections: AppType.Collection[] =
        wordCollections?.filter((wc) => !_collections.includes(wc)) ?? []

      if (deletedCollections.length !== 0) {
        deletedCollections.forEach((collection) => {
          // Copy the collection for modification this object
          const updatedCollection: AppType.Collection = {
            ...collection,
            wordIds: { ...collection?.wordIds },
          }

          if (updatedCollection.wordIds)
            delete updatedCollection.wordIds[`${word.id}`]

          dispatch(updateCollection(updatedCollection)).then(() => {
            // Update word list in the store with updated collection
            // so we added a new wordIds prop to collection
            dispatch(
              wordCollectionHandlers.updateCollectionInWords(updatedCollection),
            )
          })
        })
      }
    }

    // if the collections were updated sent it to the parent component
    if (!isObjectsEqual(wordCollections, _collections)) {
      onAddWordToCollection(true, _collections)
    } else {
      onAddWordToCollection(false, wordCollections)
    }

    handleClose()
  }

  const Section = (sectionProps: SectionProps) => {
    const { collections, isAllCollection } = sectionProps

    const handleAddCollection = (collection: AppType.Collection) => {
      _setCollections([..._collections, collection])
      _setAllCollections(_allCollections.filter((c) => c !== collection))
    }

    const handleRemoveCollection = (collection: AppType.Collection) => {
      _setCollections(_collections.filter((c) => c !== collection))
      _setAllCollections([..._allCollections, collection])
    }

    return (
      <>
        <Divider sx={styles.divider}>
          <Chip
            sx={styles.chip}
            label={isAllCollection ? 'All collections' : 'Selected collections'}
          />
        </Divider>

        {collections && collections.length !== 0 ? (
          collections.map((collection) => (
            <Card key={collection.id} sx={styles.card}>
              <CardActionArea
                onClick={() =>
                  isAllCollection
                    ? handleAddCollection(collection)
                    : handleRemoveCollection(collection)
                }
              >
                <CardContent>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={styles.contentStack}
                  >
                    <Stack direction="column" alignItems="start">
                      <Typography>name: {collection.name}</Typography>
                    </Stack>
                    {isAllCollection ? <AddIcon /> : <ClearIcon />}
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          ))
        ) : (
          <Typography color="text.secondary">
            There are no collections
          </Typography>
        )}
      </>
    )
  }

  return (
    <BootstrapDialog
      fullWidth
      onClose={handleClose}
      aria-labelledby="collection-dialog"
      open={isOpen}
    >
      <DialogTitle sx={styles.dialogTitle} id="collection-dialog">
        Collections
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={styles.iconButton}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Section collections={_collections} isAllCollection={false} />
        <Section collections={_allCollections} isAllCollection={true} />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleSave}
          variant="contained"
          sx={styles.button}
        >
          {_allCollections.length !== 0 || _collections.length !== 0
            ? 'Save changes'
            : 'Cancel'}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}

const styles = {
  divider: {
    m: '5px 0',
    '&::before, &::after': {
      borderColor: typography.colors.green,
    },
  },

  iconButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme: Theme) => theme.palette.grey[500],
  },

  dialogTitle: { m: 0, p: 2 },

  chip: { backgroundColor: typography.colors.green },

  card: { mb: 1, backgroundColor: typography.colors.card },

  contentStack: { marginBottom: 1 },

  button: { m: 1, color: typography.colors.white },
}

export default CollectionsDialog
