import { FC, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { motion } from 'framer-motion'
import { toast } from 'react-toastify'
import { Form, FormikProvider, useFormik } from 'formik'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { School } from '@mui/icons-material'
import { history } from '@utils/history'
import { validation } from '@utils/validation'
import routes from '@/pages/routes'
import { useAppDispatch } from '@hooks'
import { addWord, updateWord } from '@reducers/wordSlice'
import { typography } from '@/styles/typography'
import { style } from '@/styles/global'
import AppType from '@/types/AppType'
import { AppDispatch } from '@store'
import LanguageSelector from '@/components/elements/LanguageSelector'
import { useSelector } from 'react-redux'
import CollectionsDialog from '@/components/words/CollectionsDialog'
import { addWordToCollections } from '@reducers/wordCollectionHandlers'
import { getLanguage, setLanguage } from '@reducers/languageSlice'
import { wordService } from '@services/wordService'

type AddWordFormProps = {
  word?: AppType.Word
}

const handleAddWord = async (
  dispatch: AppDispatch,
  word: AppType.Word,
  isNewWord: boolean,
  isCollectionsUpdated: boolean,
) => {
  // Copy collections from the word
  // (else we lost them after the word will be added or updated)
  const updatedWord: AppType.Word = { ...word }

  const updateCollections = () => {
    if (isCollectionsUpdated) {
      addWordToCollections(dispatch, updatedWord)
    }
  }

  const handleUpdateWord = async (wordToUpdate: AppType.Word) => {
    await dispatch(updateWord(wordToUpdate))
      .then(() => {
        updateCollections()
        toast.success(`The word has been updated.`)
        history.navigate(routes.words)
      })
      .catch((error) => {
        console.error(error)
        toast.error(validation.errorMessages.somethingWrong)
      })
  }

  const handleAddNewWord = async (newWord: AppType.Word) => {
    await dispatch(addWord(newWord))
      .then(() => {
        updateCollections()
        toast.success(`The word has been added.`)
        history.navigate(routes.words)
      })
      .catch((error) => {
        console.error(error)
        toast.error(validation.errorMessages.somethingWrong)
      })
  }

  isNewWord ? handleAddNewWord(word) : handleUpdateWord(word)
}

const AddWordForm: FC<AddWordFormProps> = ({ word }) => {
  //#region state
  const dispatch = useAppDispatch()
  const userLanguage: AppType.Language = useSelector(getLanguage)
  const [fromLanguage, setFromLanguage] = useState(
    word?.from ?? (userLanguage.from?.label || ''),
  )
  const [toLanguage, setToLanguage] = useState(
    word?.to ?? (userLanguage.to?.label || ''),
  )
  const originalWordCollections = word?.collections ?? []
  const [collections, setCollections] = useState<AppType.Collection[]>(
    originalWordCollections,
  )
  const [isCollectionsDialogOpen, setCollectionsDialogOpen] = useState(false)
  const [isCollectionsUpdated, setCollectionsUpdated] = useState(false)
  //#endregion state

  //#region form
  const defaultWordValues = {
    original: '',
    translation: '',
    from: fromLanguage,
    to: toLanguage,
    comment: '',
    isForLearn: false,
  }

  const formik = useFormik({
    initialValues: {
      ...defaultWordValues,
      ...word,
    },
    validationSchema: validation.WordSchema,
    onSubmit: async (values, actions) => {
      const isNewWord: boolean = !word?.id

      // Update or create new word
      const updatedWord: AppType.Word = {
        id: word ? word.id : uuidv4(),
        original: values.original.trim(),
        translation: values.translation.trim(),
        from: values.from,
        to: values.to,
        comment: values.comment ? values.comment.trim() : null,
        isForLearn: values?.isForLearn ?? false,
        collections,
      }

      await handleAddWord(
        dispatch,
        updatedWord,
        isNewWord,
        isCollectionsUpdated,
      )

      // Stop the loader in case of errors
      actions.setSubmitting(false)
    },
  })

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik
  //#endregion form

  //#region language
  const handleFromLanguageChange = async (language: AppType.LanguageOption) => {
    await handleLanguageChange('from', language)
  }

  const handleToLanguageChange = async (language: AppType.LanguageOption) => {
    await handleLanguageChange('to', language)
  }

  const handleLanguageChange = async (
    direction: 'from' | 'to',
    language: AppType.LanguageOption,
  ) => {
    formik.setFieldValue(direction, language.label)

    let updatedLanguage: AppType.Language = {}

    if (direction === 'from') {
      setFromLanguage(language.label)
      updatedLanguage = {
        from: language,
        to: userLanguage.to ?? { value: '', label: '' },
      }
    } else {
      setToLanguage(language.label)
      updatedLanguage = {
        from: userLanguage.from ?? { value: '', label: '' },
        to: language,
      }
    }

    await dispatch(setLanguage(updatedLanguage))
  }

  const onReverseLanguages = async () => {
    const updatedLanguage: AppType.Language = {
      from: userLanguage.to,
      to: userLanguage.from,
    }

    await dispatch(setLanguage(updatedLanguage))

    const from = updatedLanguage.from!.label
    const to = updatedLanguage.to!.label

    formik.setFieldValue('from', from)
    formik.setFieldValue('to', to)
    setFromLanguage(from)
    setToLanguage(to)
  }

  const onTranslateWord = async () => {
    if (fromLanguage === toLanguage) {
      toast.warning('You use the same language')
      return
    }

    if (!fromLanguage) {
      toast.warning("Please, select translate 'from'")
      return
    }

    if (!toLanguage) {
      toast.warning("Please, select translate 'to'")
      return
    }

    try {
      await wordService
        .translateWord(formik.values.original, userLanguage)
        .then((res) => res.json())
        .then(
          (res) => {
            const translation = res.data.translations[0]?.translatedText
            formik.setFieldValue('translation', translation)
          },
          (error) => {
            throw error
          },
        )
    } catch (error) {
      toast.error('Translation error occurred.')
      throw error
    }
  }
  //#endregion language

  //#region collections dialog

  const handleOpenCollectionsDialog = () => {
    setCollectionsDialogOpen(true)
  }

  const handleCloseCollectionsDialog = () => {
    setCollectionsDialogOpen(false)
  }

  const handleAddWordToCollection = (
    isCollectionsUpdated: boolean,
    updatedCollections: AppType.Collection[],
  ) => {
    if (isCollectionsUpdated) {
      setCollectionsUpdated(isCollectionsUpdated)

      // Add updated collections to the word
      setCollections(updatedCollections)
    }
  }
  //#endregion collections dialog

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 60 }}
      animate={style.fadeInUp.animate}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={style.fadeInUp.animate}
          >
            <LanguageSelector
              value={fromLanguage}
              label="From"
              errors={errors.from}
              touched={touched.from}
              onChange={handleFromLanguageChange}
            />

            <Button
              fullWidth
              variant="outlined"
              style={style.button.bold}
              onClick={() => onReverseLanguages()}
              disabled={!Boolean(values.from && values.to)}
            >
              Revers
            </Button>

            <LanguageSelector
              value={toLanguage}
              label="To"
              errors={errors.to}
              touched={touched.to}
              onChange={handleToLanguageChange}
            />

            <TextField
              fullWidth
              type="text"
              label="Word"
              placeholder="Enter a word"
              {...getFieldProps('original')}
              error={Boolean(touched.original && errors.original)}
              helperText={touched.original && errors.original}
            />

            <TextField
              fullWidth
              type="text"
              label="Translation"
              placeholder="Translate the word"
              {...getFieldProps('translation')}
              error={Boolean(touched.translation && errors.translation)}
              helperText={touched.translation && errors.translation}
            />

            <Button
              fullWidth
              variant="outlined"
              style={style.button.bold}
              onClick={() => onTranslateWord()}
              disabled={!Boolean(values.original)}
            >
              Translate
            </Button>

            <TextField
              fullWidth
              type="text"
              label="Comment"
              placeholder="Left a comment (if needed)"
              {...getFieldProps('comment')}
              error={Boolean(touched.comment && errors.comment)}
              helperText={touched.comment && errors.comment}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={!!values.isForLearn}
                  icon={<School />}
                  checkedIcon={<School />}
                />
              }
              label={
                (!!values.isForLearn ? 'Selected' : 'Select') + ' for learning'
              }
              {...getFieldProps('isForLearn')}
            />

            <Button variant="outlined" onClick={handleOpenCollectionsDialog}>
              {collections.length !== 0
                ? 'Update Collections'
                : 'Add To Collection'}
            </Button>

            <CollectionsDialog
              isOpen={isCollectionsDialogOpen}
              onClose={handleCloseCollectionsDialog}
              word={word}
              onAddWordToCollection={(
                isCollectionsUpdated,
                updatedCollections,
              ) =>
                handleAddWordToCollection(
                  isCollectionsUpdated,
                  updatedCollections,
                )
              }
            />
          </Box>

          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={style.fadeInUp.animate}
            mt={3}
          >
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ color: typography.colors.white }}
              loading={isSubmitting}
            >
              {isSubmitting ? 'Loading...' : word ? 'Update' : 'Save'}
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  )
}

export default AddWordForm
