import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import { motion } from 'framer-motion'
import routes from '@/pages/routes'
import AddCollectionForm from '@/components/collections/AddCollectionForm'
import Wrapper from '@/components/elements/Wrapper'
import AppBar from '@/components/elements/navigation/AppBar'
import { style } from '@/styles/global'

const AddCollection = () => {
  const { state } = useLocation()
  const [collection] = useState(state?.collection)

  return (
    <>
      <AppBar title="Collections" linkBackTo={routes.collections} />

      <Wrapper>
        <style.Content>
          <style.Heading component={motion.div} {...style.fadeInUp}>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              {collection ? 'Edit' : 'Add'} Collection
            </Typography>
          </style.Heading>

          {collection ? (
            <AddCollectionForm collection={collection} />
          ) : (
            <AddCollectionForm />
          )}
        </style.Content>
      </Wrapper>
    </>
  )
}

export default AddCollection
