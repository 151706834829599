import { createTheme } from '@mui/material/styles'
import { typography } from '@/styles/typography'

// https://mui.com/customization/theming/#themes

const theme = createTheme({
  palette: {
    primary: {
      main: typography.colors.primary,
    },
    secondary: {
      main: typography.colors.secondary,
    },
    background: {
      default: typography.colors.card,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: typography.colors.secondary,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // borderBottom: '1px solid ' + typography.colors.secondary,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: typography.colors.secondary,
        },
        expandIconWrapper: {
          color: typography.colors.secondary,
        },
      },
    },
  },
})

export default theme
