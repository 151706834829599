import { Form, FormikProvider, useFormik } from 'formik'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { motion } from 'framer-motion'
import sendEmail from '@utils/emailjs'
import { validation } from '@utils/validation'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const SupportForm: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: validation.SupportSchema,
    onSubmit: async (values) => {
      const formData = {
        name: values.name.trim(),
        email: values.email,
        message: values.message,
      }

      try {
        await sendEmail(formData)
        formik.resetForm()
      } catch (error) {
        console.error('Error submitting support request:', error)
      }
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          component={motion.div}
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
            component={motion.div}
            initial={{ opacity: 0, y: 40 }}
            animate={style.fadeInUp.animate}
          >
            <TextField
              fullWidth
              required
              type="text"
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              required
              type="email"
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              required
              type="text"
              label="Message"
              {...getFieldProps('message')}
              error={Boolean(touched.message && errors.message)}
              helperText={touched.message && errors.message}
              multiline
              minRows={4}
              maxRows={4}
            />
          </Box>

          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 80 }}
            animate={style.fadeInUp.animate}
            mt={3}
          >
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ color: typography.colors.white }}
              loading={isSubmitting}
            >
              {isSubmitting ? 'Loading...' : 'Submit'}
            </LoadingButton>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  )
}

export default SupportForm
