import Box from '@mui/material/Box'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { typography } from '@/styles/typography'

type CircularProgressWithLabelProps = {
  value: number
  labelcolor: string // use lowercase to fix the react DOM warning
  diameter: number
} & CircularProgressProps

const CircularProgressWithLabel = (props: CircularProgressWithLabelProps) => {
  const { value, labelcolor, diameter } = props
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{ color: typography.colors.greyLight, position: 'absolute' }}
        size={diameter}
      />
      <CircularProgress variant="determinate" size={diameter} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography component="div" color={labelcolor}>{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

export default CircularProgressWithLabel
