import { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { Field, FieldAttributes, Form, FormikProvider, useFormik } from 'formik'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { authService } from '@/shared/services/authService'
import { validation } from '@utils/validation'
import { typography } from '@/styles/typography'

const PasswordUpdateForm: FC = () => {
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  const formik = useFormik({
    initialValues: { oldPassword: '', newPassword: '', repeatPassword: '' },
    validationSchema: validation.PasswordUpdateSchema,
    onSubmit: async (values, actions) => {
      try {
        await authService
          .updateUserPassword(values.oldPassword, values.newPassword)
          .then(() => {
            toast.success('Password updated successfully!')
            actions.resetForm()
          })
          .catch((error) => {
            const errorWithCode = error as { code: string }
            validation.loginMessages(errorWithCode.code)
          })
      } catch (error) {
        console.error('Error updating password:', error)
        toast.error(
          'Failed to update password. Please check your old password and try again.',
        )
      }

      // Stop the loader in case of errors
      actions.setSubmitting(false)
    },
  })

  const { isSubmitting, dirty, handleSubmit } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {/* Hidden username field for accessibility */}
        <Field
          name="username"
          type="text"
          style={{ display: 'none' }}
          autoComplete="username"
        />
        <Field name="oldPassword">
          {({ field, meta }: FieldAttributes<any>) => (
            <TextField
              {...field}
              label="Old Password"
              type={showOldPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              autoComplete="current-password"
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowOldPassword((prev) => !prev)}
                    >
                      {showOldPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Field>
        <Field name="newPassword">
          {({ field, meta }: FieldAttributes<any>) => (
            <TextField
              {...field}
              label="New Password"
              type={showNewPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              autoComplete="new-password"
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowNewPassword((prev) => !prev)}
                    >
                      {showNewPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Field>

        <Field name="repeatPassword">
          {({ field, meta }: FieldAttributes<any>) => (
            <TextField
              {...field}
              label="Repeat Password"
              type={showNewPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              autoComplete="new-password"
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
            />
          )}
        </Field>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ color: typography.colors.white, mt: 2 }}
          disabled={isSubmitting || !dirty}
          loading={isSubmitting}
        >
          Update
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
}

export default PasswordUpdateForm
