import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@store'
import { logout } from '@reducers/authSlice'
import { WordAndCollectionState } from '@reducers/collectionSlice'
import { initUserData } from '@reducers/user/initUserData'
import {
  removeCollectionFromWord,
  updateCollectionInWords,
} from '@reducers/wordCollectionHandlers'
import { getWordsWithCollections } from '@utils/getWordsWithCollections'
import sortByCreated from '@utils/sortByCreated'
import { validation } from '@utils/validation'
import { wordService } from '@services/wordService'
import AppType from '@/types/AppType'

type WordState = AppType.CommonState & AppType.Word
type WordsState = AppType.CommonState & AppType.Word[]

export const initializeWords = (
  words?: AppType.Word[],
  collections?: AppType.Collection[],
): WordsState => {
  const wordsWithCollections = words
    ? getWordsWithCollections(words, collections || [])
    : []

  return wordsWithCollections
}

const initialState: WordsState = initializeWords()

export const addWord = createAsyncThunk<WordState, AppType.Word>(
  'addWord',
  async (word, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState
      const userId = state?.user.id

      if (!userId) throw new Error(validation.errorMessages.invalidId)

      await wordService.addWord(word, userId).catch((error) => {
        throw error
      })

      return thunkAPI.fulfillWithValue(word)
    } catch (error) {
      throw error
    }
  },
)

export const updateWord = createAsyncThunk<WordState, AppType.Word>(
  'updateWord',
  async (word, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState
      if (!state) return {}

      const userId = state.user.id
      if (!userId) throw new Error(validation.errorMessages.invalidId)

      await wordService.updateWord(word, userId).catch((error) => {
        throw error
      })

      return thunkAPI.fulfillWithValue(word)
    } catch (error) {
      throw error
    }
  },
)

export const removeWord = createAsyncThunk<WordState, AppType.Word>(
  'removeWord',
  async (word, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState
      if (!state) return {}

      const userId = state.user.id
      const wordId = word.id

      if (!wordId || !userId)
        throw new Error(validation.errorMessages.invalidId)

      await wordService.removeWord(wordId, userId).catch((error) => {
        throw error
      })

      return thunkAPI.fulfillWithValue(word)
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: (error as Error).message })
    }
  },
)

export const addCollectionToWord = createAsyncThunk<
  AppType.Word,
  WordAndCollectionState
>('addCollectionToWord', async ({ word, collection }, thunkAPI) => {
  try {
    const collections: AppType.Collection[] = word.collections || []
    const updatedWord: AppType.Word = {
      ...word,
      collections: [...collections, collection],
    }

    return thunkAPI.fulfillWithValue(updatedWord)
  } catch (error) {
    throw error
  }
})

export const setLearnStatus = createAsyncThunk<WordState, AppType.Word>(
  'setLearnStatus',
  async (word, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState
      const userId = state?.user.id

      if (!userId) throw new Error(validation.errorMessages.invalidId)

      await wordService.setLearnStatus(word, userId).catch((error) => {
        throw error
      })

      return thunkAPI.fulfillWithValue(word)
    } catch (error) {
      throw error
    }
  },
)

// Slices
export const wordSlice = createSlice({
  name: 'words',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // initUserData words
    builder.addCase(initUserData.fulfilled, (state, action) => {
      return action.payload.words
    })
    builder.addCase(initUserData.rejected, () => [])

    // addWord
    builder.addCase(addWord.fulfilled, (state, action) => {
      const { id } = action.payload
      const newWord = {
        ...action.payload,
        comment: action.payload.comment ?? '',
        created: new Date().toISOString(),
      }
      return { ...state, ...{ [`${id}`]: newWord } }
    })
    builder.addCase(addWord.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // updateWord
    builder.addCase(updateWord.fulfilled, (state, action) => {
      const id = action.payload.id!
      return {
        ...state,
        [id]: {
          ...state[id as any],
          ...action.payload,
          comment: action.payload.comment ?? '',
        },
      }
    })
    builder.addCase(updateWord.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // updateCollectionInWords
    builder.addCase(updateCollectionInWords.fulfilled, (state, action) => {
      return action.payload
    })

    // removeWord
    builder.addCase(removeWord.fulfilled, (state, action) => {
      const id = action.payload.id
      const newState = { ...state }
      delete newState[id as any]
      return newState
    })
    builder.addCase(removeWord.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // removeCollectionFromWord
    builder.addCase(removeCollectionFromWord.fulfilled, (state, action) => {
      const id = action.payload.id!
      return {
        ...state,
        [id]: {
          ...state[id as any],
          ...action.payload,
        },
      }
    })
    builder.addCase(removeCollectionFromWord.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // addCollectionToWord
    builder.addCase(addCollectionToWord.fulfilled, (state, action) => {
      const id = action.payload.id!
      return {
        ...state,
        [id]: {
          ...state[id as any],
          ...action.payload,
        },
      }
    })
    builder.addCase(addCollectionToWord.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // setLearnStatus
    builder.addCase(setLearnStatus.fulfilled, (state, action) => {
      const id = action.payload.id!
      return {
        ...state,
        [id]: {
          ...state[id as any],
          ...action.payload,
        },
      }
    })
    builder.addCase(setLearnStatus.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // logout
    builder.addCase(logout.fulfilled, () => [])
  },
})

// Selectors
export const wordsSelector = (state: RootState) => (state ? state.words : [])

export const allWordsSelector = createSelector(
  wordsSelector,
  (words) => Object.values(words) ?? [],
)

export const sortByDateWordsSelector = createSelector(wordsSelector, (words) =>
  sortByCreated(words),
)

export const wordsForLearningSelector = createSelector(wordsSelector, (words) =>
  Object.values(words).filter((word) => word.isForLearn === true),
)

export default wordSlice.reducer
