import { useLocation, useNavigate } from 'react-router'
import { history } from '@utils/history'
import Footer from '@/components/elements/Footer'
import AppRoutes from '@/components/routes/AppRoutes'

const App = () => {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate()
  history.location = useLocation()

  return (
    <>
      <AppRoutes />
      <br />
      <Footer />
    </>
  )
}

export default App
