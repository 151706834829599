import { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CircularProgressWithLabel from '@/components/elements/CircularProgressWithLabel'
import Flexbox from '@/components/elements/Flexbox'
import { typography } from '@/styles/typography'

type ResultCardProps = {
  wordsLength: number
  progress: number
  correct: number
  learnAgain: number
  onClose: () => void
  onStartOver: () => void
}

const ResultCard = (props: ResultCardProps) => {
  const { progress, wordsLength, correct, learnAgain, onClose, onStartOver } =
    props
  const [totalWords, setTotalProgress] = useState(0)
  const [learnedWords, setLearnedProgress] = useState(0)
  const [learnAgainWords, setLearnAgainProgress] = useState(0)

  useEffect(() => {
    // CircularProgressWithLabel animation
    const timer = setInterval(() => {
      setTotalProgress((progress * 100) / wordsLength)
      setLearnedProgress((correct * 100) / wordsLength)
      setLearnAgainProgress((learnAgain * 100) / wordsLength)
    }, 0)
    return () => {
      clearInterval(timer)
    }
  }, [progress, correct, learnAgain, wordsLength])

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        title="Your progress"
        action={
          <IconButton onClick={onClose}>
            <ClearIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Flexbox>
          <CircularProgressWithLabel
            value={totalWords}
            labelcolor={typography.colors.blue}
            sx={{ color: typography.colors.blue }}
            diameter={150}
          />
          <Typography
            align="center"
            sx={{ color: typography.colors.blue, marginTop: 1 }}
          >
            Total {progress} word{progress > 1 ? 's' : null}
          </Typography>
        </Flexbox>
        <br />
        <Flexbox flexDirection="row" justifyContent="space-around">
          <Flexbox>
            <CircularProgressWithLabel
              value={learnedWords}
              labelcolor={typography.colors.primary}
              sx={{ color: typography.colors.primary }}
              diameter={100}
            />
            <Typography
              variant="subtitle2"
              align="center"
              sx={{ color: typography.colors.primary, marginTop: 1 }}
            >
              {correct} word{correct > 1 ? 's' : null}
              <br />
              Learned
              <br />
              <br />
            </Typography>
          </Flexbox>
          <Flexbox>
            <CircularProgressWithLabel
              value={learnAgainWords}
              labelcolor={typography.colors.red}
              diameter={100}
              sx={{ color: typography.colors.red }}
            />
            <Typography
              align="center"
              variant="subtitle2"
              sx={{ color: typography.colors.red, marginTop: 1 }}
            >
              {learnAgain} word{learnAgain > 1 ? 's' : null}
              <br />
              Returned to
              <br />
              "Learn again"
            </Typography>
          </Flexbox>
        </Flexbox>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          padding: 2,
        }}
      >
        <Button
          size="large"
          variant="outlined"
          sx={{ width: 160 }}
          onClick={onStartOver}
        >
          Start Over
        </Button>
      </CardActions>
    </Card>
  )
}

export default ResultCard
