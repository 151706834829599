import { Navigate } from 'react-router-dom'
import { useAppSelector } from '@hooks'
import { isUserAuthenticatedSelector } from '@reducers/authSlice'
import { history } from '@utils/history'
import routes from '@/pages/routes'

type ProtectedRouteProps = {
  children: JSX.Element
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const authenticated = useAppSelector(isUserAuthenticatedSelector)

  if (authenticated) {
    // authorized so return child components
    return children
  }

  // not logged in so redirect to login page with the return url
  return <Navigate to={routes.home} state={{ from: history.location }} />
}

export default ProtectedRoute
