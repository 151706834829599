import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { motion } from 'framer-motion'
import { useAppDispatch } from '@hooks'
import {
  collectionByIdSelector,
  removeWordFromCollection,
} from '@/shared/redux/reducers/collectionSlice'
import { removeCollectionFromWord } from '@/shared/redux/reducers/wordCollectionHandlers'
import { allWordsSelector } from '@/shared/redux/reducers/wordSlice'
import { getWordsOfCollection } from '@/shared/utils/getWordsOfCollection'
import routes from '@/pages/routes'
import AppType from '@/types/AppType'
import WordsDialog from '@/components/collections/WordsDialog'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import ListenButton from '@/components/elements/ListenButton'
import Wrapper from '@/components/elements/Wrapper'
import AppBar from '@/components/elements/navigation/AppBar'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const ViewCollection = () => {
  const dispatch = useAppDispatch()
  const { collectionId } = useParams()
  const [collection, setCollection] = useState<AppType.Collection>(
    useSelector(collectionByIdSelector(collectionId!)) as AppType.Collection,
  )
  const words = useSelector(allWordsSelector)
  const wordsOfCollection: AppType.Word[] = getWordsOfCollection(
    collection,
    words,
  )
  const [isWordDialogOpen, setIsWordDialogOpen] = useState(false)

  const handleCloseWordDialog = () => {
    setIsWordDialogOpen(false)
  }

  const handleUpdateCollection = (collection: AppType.Collection) => {
    setCollection(collection)
  }

  const onAddWord = () => {
    setIsWordDialogOpen(true)
  }

  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [wordToDelete, setWordToDelete] = useState<AppType.Word | null>(null)

  const handleOpenConfirmDialog = (word: AppType.Word) => {
    setWordToDelete(word)
    setConfirmDialogOpen(true)
  }

  const handleDeleteConfirm = () => {
    const updatedWordIds = { ...collection?.wordIds }
    delete updatedWordIds[`${wordToDelete!.id}`]

    const updatedCollection: AppType.Collection = {
      ...collection,
      wordIds: updatedWordIds,
    }

    dispatch(
      removeWordFromCollection({
        word: wordToDelete!,
        collection: updatedCollection,
      }),
    )
      .then(() => {
        dispatch(
          removeCollectionFromWord({
            word: wordToDelete!,
            collection: updatedCollection,
          }),
        ).then(() =>
          toast.success('The word has been removed from collection.'),
        )
      })
      .catch((error) => {
        console.error(error)
      })

    setCollection(updatedCollection)
    setWordToDelete(null)
    setConfirmDialogOpen(false)
  }

  const handleDeleteCancel = () => {
    setConfirmDialogOpen(false)
  }

  return (
    <>
      <AppBar title="Collections" linkBackTo={routes.collections} />

      <Wrapper>
        <Stack component={motion.div} {...style.fadeInUp}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: 60 }}
          >
            <Typography variant="h5">{collection.name}</Typography>
            <Button
              sx={{ color: typography.colors.secondary }}
              size="small"
              variant="outlined"
              style={style.button.bold}
              onClick={onAddWord}
            >
              Add Word
            </Button>
          </Stack>

          {wordsOfCollection.length !== 0 ? (
            wordsOfCollection.map((word) => (
              <Card key={word.id} sx={{ mb: 1 }}>
                <CardContent>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginBottom: 1 }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <ListenButton
                        word={word.translation!}
                        language={word.to!}
                      />
                      <Stack
                        direction="column"
                        alignItems="start"
                        sx={{ marginLeft: 2 }}
                      >
                        <Typography>original: {word.original}</Typography>
                        <Typography>translation: {word.translation}</Typography>
                        <Typography>from: {word.from}</Typography>
                        <Typography>to: {word.to}</Typography>
                      </Stack>
                    </Stack>
                    <IconButton onClick={() => handleOpenConfirmDialog(word)}>
                      <ClearIcon />
                    </IconButton>
                  </Stack>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              There are no words
            </Typography>
          )}
        </Stack>
      </Wrapper>

      {isWordDialogOpen ? (
        <WordsDialog
          isOpen={isWordDialogOpen}
          onClose={handleCloseWordDialog}
          setCollection={(updatedCollection) =>
            handleUpdateCollection(updatedCollection)
          }
          collection={collection}
          wordsOfCollection={wordsOfCollection}
        />
      ) : null}

      {isConfirmDialogOpen ? (
        <ConfirmationDialog
          isOpen={isConfirmDialogOpen}
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          title="Delete"
          message="Are you sure you want to remove it?"
          confirmLabel="Delete"
          isRedButton={true}
        />
      ) : null}
    </>
  )
}

export default ViewCollection
