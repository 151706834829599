import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { wordsForLearningSelector } from '@/shared/redux/reducers/wordSlice'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import AppType from '@/types/AppType'
import Wrapper from '@/components/elements/Wrapper'
import AppBar from '@/components/elements/navigation/AppBar'
import FlashCard from '@/components/learn/FlashCard'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const LearnAllMarkedToLearnWords = () => {
  const wordsForLearning = useSelector(wordsForLearningSelector)
  const [words] = useState<AppType.Word[]>(wordsForLearning)

  return (
    <>
      <AppBar title="Flash Cards" linkBackTo={routes.flashCardOptions} />
      <Wrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 60 }}
        >
          <Typography variant="h5">Learn All Marked To Learn Words</Typography>
        </Stack>
        <style.Content>
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={style.fadeInUp.animate}
          >
            {words && !words.length ? (
              <>
                <style.Heading>
                  <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                    You don't have any words for learning.
                  </Typography>
                </style.Heading>
                <Button
                  sx={{ color: typography.colors.secondary }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  style={style.button.bold}
                  onClick={() => history.navigate(routes.words)}
                >
                  Go to Words
                </Button>
              </>
            ) : (
              <FlashCard words={words} />
            )}
          </Box>
        </style.Content>
      </Wrapper>
    </>
  )
}

export default LearnAllMarkedToLearnWords
