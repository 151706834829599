import { CardContent, Stack, Typography } from '@mui/material'
import AppType from '@/types/AppType'

type CollectionCardProps = {
  collection: AppType.Collection
  children?: React.ReactNode
}

const CollectionCard = (props: CollectionCardProps) => {
  const { collection, children } = props
  const wordsCount = Object.keys(collection.wordIds || {}).length

  return (
    <CardContent>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 1 }}
      >
        <Stack direction="column" alignItems="start">
          <Typography>name: {collection.name}</Typography>
          <Typography>
            {wordsCount > 0 ? (
              <>
                {wordsCount} word{wordsCount > 1 ? 's' : ''}
              </>
            ) : (
              <>There are no words</>
            )}
          </Typography>
        </Stack>
        {children}
      </Stack>
    </CardContent>
  )
}

export default CollectionCard
