import { Box, Button, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import Wrapper from '@/components/elements/Wrapper'
import AppBar from '@/components/elements/navigation/AppBar'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const FlashCardOptions = () => {
  return (
    <>
      <AppBar title="Learn" linkBackTo={routes.learn} />
      <Wrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 60 }}
        >
          <Typography variant="h5">Flash Cards</Typography>
        </Stack>
        <style.Content>
          <style.Heading component={motion.div} {...style.fadeInUp}>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              Choose your learning source option:
            </Typography>
          </style.Heading>
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={style.fadeInUp.animate}
          >
            <Button
              sx={{ color: typography.colors.secondary }}
              fullWidth
              size="small"
              variant="outlined"
              style={style.button.bold}
              onClick={() =>
                history.navigate(routes.wordsFromSelectedCollection)
              }
            >
              Select Collection to Learn
            </Button>

            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                textAlign: 'center',
                mt: 2,
                mb: 5,
              }}
            >
              You may select any collection. <br />
              All words from the selected collection will be send to learn.
            </Typography>

            <Button
              sx={{ color: typography.colors.secondary }}
              fullWidth
              size="small"
              variant="outlined"
              style={style.button.bold}
              onClick={() => history.navigate(routes.allMarkedToLearnWords)}
            >
              Learn all my marked to learn words
            </Button>

            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                textAlign: 'center',
                mt: 2,
                mb: 5,
              }}
            >
              All words you marked to Learn will be send to learn.
            </Typography>
          </Box>
        </style.Content>
      </Wrapper>
    </>
  )
}

export default FlashCardOptions
