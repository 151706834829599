import { isValidIds } from '@utils/isValidIds'
import { validation } from '@utils/validation'
import AppType from '@/types/AppType'
import { getDatabase, ref, set, update } from '@firebase/database'

const addLanguageToDB = async (language: AppType.Language, userId: string) => {
  try {
    if (!isValidIds(userId)) throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const languageRef = ref(db, `users/${userId}/language/`)

    await update(languageRef, language)
  } catch (error) {
    console.error('Language addition error:', error)
    throw error
  }
}

const addRecentLanguagesToDB = async (
  recentLanguages: AppType.LanguageOption[],
  userId: string,
) => {
  try {
    if (!isValidIds(userId)) throw new Error(validation.errorMessages.invalidId)

    const db = getDatabase()
    const recentLanguagesRef = ref(db, `users/${userId}/recentLanguages/`)

    await set(recentLanguagesRef, recentLanguages)
  } catch (error) {
    console.error('Recent language addition error:', error)
    throw error
  }
}

export const languageAPI = {
  addLanguageToDB,
  addRecentLanguagesToDB,
}
