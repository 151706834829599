import { FC } from 'react'
import { MenuItem, TextField } from '@mui/material'
import { LANGUAGES } from '@utils/languages'
import AppType from '@/types/AppType'

interface LanguageSelectorProps {
  value: string
  label: string
  errors: string | undefined
  touched: boolean | undefined
  onChange: (language: AppType.LanguageOption) => void
}

const LanguageSelector: FC<LanguageSelectorProps> = ({
  value,
  label,
  errors,
  touched,
  onChange,
}) => {
  return (
    <TextField
      fullWidth
      type="text"
      label={label}
      select
      value={value}
      onChange={(e) => {
        const label = e.target.value
        const value =
          LANGUAGES.find((language) => language.label === label)?.value || ''
        onChange({ label, value })
      }}
      error={Boolean(touched && errors)}
      helperText={touched && errors}
    >
      {LANGUAGES.map((language) => (
        <MenuItem key={language.value} value={language.label}>
          {language.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default LanguageSelector
