/**
 * This file includes all the reducers under reducers directory,
 * Import all and add to combineReducers to use any among whole app
 */
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AnyAction, combineReducers } from '@reduxjs/toolkit'
import authReducer from '@reducers/authSlice'
import collectionReducer from '@reducers/collectionSlice'
import languageSlice from '@reducers/languageSlice'
import userReducer from '@reducers/user/userSlice'
import wordReducer from '@reducers/wordSlice'

const persistConfig = {
  key: 'root',
  storage,
}

export type AppState = ReturnType<typeof appReducer> | undefined

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  words: wordReducer,
  collections: collectionReducer,
  language: languageSlice,
})

const rootReducer = (state: AppState, action: AnyAction) => {
  if (action.type === 'logout/fulfilled') {
    state = undefined
  }
  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
