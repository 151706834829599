import { toast } from 'react-toastify'
import { Form, FormikProvider, useFormik } from 'formik'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, TextField } from '@mui/material'
import { motion } from 'framer-motion'
import { history } from '@utils/history'
import { validation } from '@utils/validation'
import { authService } from '@services/authService'
import routes from '@/pages/routes'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const handleForgotPassword = async (email: string) => {
  await authService
    .passwordResetEmail(email)
    .then(() => {
      history.navigate(routes.signin)
      toast.success('The email with further instructions has been sent.')
    })
    .catch((error) => {
      const customError = error as { code: string }
      validation.forgotPasswordMessages(customError.code)
    })
}

const ForgotPasswordForm = () => {
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: validation.ForgotPasswordSchema,
    onSubmit: async (values, actions) => {
      await handleForgotPassword(values.email.trim())
      // stop the loader in case errors
      actions.setSubmitting(false)
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          component={motion.div}
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mb: 3,
            }}
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={style.fadeInUp.animate}
          >
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email Address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Box>

          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={style.fadeInUp.animate}
          >
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ color: typography.colors.white }}
              loading={isSubmitting}
            >
              {isSubmitting ? 'Loading...' : 'Send Instructions'}
            </LoadingButton>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  )
}

export default ForgotPasswordForm
