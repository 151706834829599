import { FC } from 'react'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import { Form, FormikProvider, useFormik } from 'formik'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, TextField } from '@mui/material'
import { motion } from 'framer-motion'
import { AppDispatch } from '@store'
import { useAppDispatch } from '@hooks'
import { addCollection, updateCollection } from '@reducers/collectionSlice'
import { updateCollectionInWords } from '@reducers/wordCollectionHandlers'
import { addCollectionToWord } from '@reducers/wordSlice'
import { history } from '@utils/history'
import { validation } from '@utils/validation'
import routes from '@/pages/routes'
import AppType from '@/types/AppType'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

type AddCollectionFormProps = {
  collection?: AppType.Collection
  word?: AppType.Word
}

const handleAddCollection = async (
  collection: AppType.Collection,
  dispatch: AppDispatch,
  word?: AppType.Word,
) => {
  const handleUpdateCollection = async (
    collectionToUpdate: AppType.Collection,
  ) => {
    await dispatch(updateCollection(collectionToUpdate))
      .then(() => {
        // Check if wordIds is not empty, update words list in store
        if (
          collectionToUpdate.wordIds &&
          Object.keys(collectionToUpdate.wordIds).length > 0
        ) {
          dispatch(updateCollectionInWords(collectionToUpdate))
        }

        toast.success(`The collection has been updated.`)
      })
      .catch((error) => {
        console.error(error)
        toast.error(validation.errorMessages.somethingWrong)
      })
  }

  const handleAddNewCollection = async (
    newCollection: AppType.Collection,
    word?: AppType.Word,
  ) => {
    await dispatch(addCollection(newCollection))
      .then(() => {
        toast.success(`The collection has been added.`)
      })
      .catch((error) => {
        console.error(error)
        toast.error(validation.errorMessages.somethingWrong)
      })

    if (word) {
      // Add a new collection with a word, from Words screen
      await dispatch(addCollectionToWord({ collection, word }))
    }
  }

  // If the collection has created property this means the existing collection
  if (collection.created) {
    handleUpdateCollection(collection)
    history.navigate(routes.collections)
    return
  }

  /**
   * if user clicks 'move to folder' option on word card
   * and clicks 'add new collection' button
   */
  if (word) {
    collection = {
      ...collection,
      wordIds: { [`${word.id}`]: true },
    }

    handleAddNewCollection(collection, word)
    history.navigate(routes.words)
  } else {
    // else user creates an empty collection
    handleAddNewCollection(collection)
    history.navigate(routes.collections)
  }
}

const AddCollectionForm: FC<AddCollectionFormProps> = ({
  collection,
  word,
}) => {
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: { name: collection?.name || '' },
    validationSchema: validation.CollectionSchema,
    onSubmit: async (values, actions) => {
      const name = values.name.trim()

      // If collection is update it or create new
      const updatedCollection: AppType.Collection = collection
        ? { ...collection, name }
        : { id: uuidv4(), name }

      await handleAddCollection(updatedCollection, dispatch, word)

      // Stop the loader in case of errors
      actions.setSubmitting(false)
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 60 }}
      animate={style.fadeInUp.animate}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="Collection"
            placeholder="Enter a collection name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ mt: 3, color: typography.colors.white }}
            loading={isSubmitting}
          >
            {isSubmitting ? 'Saving...' : collection ? 'Update' : 'Save'}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </Box>
  )
}

export default AddCollectionForm
