import Wrapper from '@/components/elements/Wrapper'
import Nav from '@/components/elements/navigation/Nav'
import Faq from '@/pages/support/components/Faq'

const FaqPage: React.FC = () => {
  return (
    <>
      <Nav />
      <Wrapper>
        <Faq />
        <br />
      </Wrapper>
    </>
  )
}

export default FaqPage
