import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@store'
import { initUserData } from '@reducers/user/initUserData'
import { validation } from '@utils/validation'
import { authService } from '@services/authService'
import AppType from '@/types/AppType'

type AuthState = AppType.CommonState & AppType.Auth & AppType.User

const initialState: AuthState = {
  authenticated: false,
}

interface PayLoad {
  email: string
  password: string
  authenticated?: boolean
  id?: string | null
  displayName?: string | null
}

export const logInWithEmailAndPassword = createAsyncThunk<AuthState, PayLoad>(
  'logInWithEmailAndPassword',
  async (userData, thunkAPI) => {
    try {
      const { user } = await authService.logInWithEmailAndPassword(
        userData.email,
        userData.password,
      )
      return thunkAPI.fulfillWithValue({ id: user.uid })
    } catch (error) {
      const errorWithCode = error as { code: string }
      validation.loginMessages(errorWithCode.code)
      throw error
    }
  },
)

export const register = createAsyncThunk<AuthState, PayLoad>(
  'register',
  async (userData, thunkAPI) => {
    try {
      const { user } = await authService.addUserWithEmailAndPassword(
        userData.email,
        userData.password,
      )
      const newUser: AppType.User = {
        id: user.uid,
        email: user.email,
        displayName: user.displayName ?? userData.displayName,
        photoURL: user.photoURL,
      }
      return thunkAPI.fulfillWithValue(newUser)
    } catch (error) {
      const errorWithCode = error as { code: string }
      validation.registerMessages(errorWithCode.code)
      throw error
    }
  },
)

export const socialAuth = createAsyncThunk<AuthState, string>(
  'socialAuth',
  async (authProviderType, thunkAPI) => {
    try {
      const userData = await authService.addUserWithSocPopup(authProviderType)
      const { uid, email, displayName, photoURL } = userData.user
      const user: AuthState = {
        isNewUser: userData?.isNewUser,
        id: uid,
        email,
        displayName,
        photoURL,
      }
      return thunkAPI.fulfillWithValue(user)
    } catch (error) {
      const errorWithCode = error as { code: string }
      validation.socialMessages(errorWithCode.code)
      throw error
    }
  },
)

export const logout = createAsyncThunk('logout', async (_, thunkAPI) => {
  return await authService.logOut().catch((error) => {
    return thunkAPI.rejectWithValue(error.message)
  })
})

// Slices
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authUser: (state, action) => {
      // the payload is a token, convert it to bool
      state.authenticated = !!action.payload
    },
  },
  extraReducers: (builder) => {
    // initUserData auth
    builder.addCase(initUserData.rejected, (state, action) => {
      Object.assign(state, initialState)
      state.authenticated = false
    })

    // logInWithEmailAndPassword
    builder.addCase(logInWithEmailAndPassword.fulfilled, (state, action) => {
      const userId = action.payload.id
      state.authenticated = !!userId
      state.error = initialState.error
    })
    builder.addCase(logInWithEmailAndPassword.rejected, (state, action) => {
      state.authenticated = initialState.authenticated
      state.error = action.error
    })

    // register
    builder.addCase(register.fulfilled, (state, action) => {
      const userId = action.payload.id
      state.authenticated = !!userId
      state.error = initialState.error
    })
    builder.addCase(register.rejected, (state, action) => {
      Object.assign(state, initialState)
      state.error = action.error
    })

    // socialAuth
    builder.addCase(socialAuth.fulfilled, (state, action) => {
      const userId = action.payload.id
      state.authenticated = !!userId
      state.error = initialState.error
    })
    builder.addCase(socialAuth.rejected, (state, action) => {
      Object.assign(state, initialState)
      state.error = action.error
    })

    // logout
    builder.addCase(logout.fulfilled, (state) => {
      state.authenticated = false
    })
    builder.addCase(logout.rejected, (state, action) => {
      state.authenticated = false
      state.error = action.error
    })
  },
})

// Actions
export const { authUser } = authSlice.actions

// Selectors
export const authSelector: (state: RootState) => AuthState = (
  state: RootState,
) => (state ? state.auth : {})

export const isUserAuthenticatedSelector = createSelector(
  authSelector,
  (auth) => auth.authenticated,
)

export const errorSelector = createSelector(authSelector, (auth) => auth.error)

export default authSlice.reducer
