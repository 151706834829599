import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Form, FormikProvider, useFormik } from 'formik'
import EditIcon from '@mui/icons-material/Edit'
import { LoadingButton } from '@mui/lab'
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material'
import { useAppDispatch } from '@hooks'
import {
  updateUserName,
  userDataSelector,
} from '@/shared/redux/reducers/user/userSlice'
import { validation } from '@utils/validation'
import AppType from '@/types/AppType'
import { typography } from '@/styles/typography'

const AccountDetailsForm: FC = () => {
  const dispatch = useAppDispatch()
  const user: AppType.User = useSelector(userDataSelector)
  const [editable, setEditable] = useState(false)
  const formik = useFormik({
    initialValues: { email: user?.email, name: user?.displayName },
    validationSchema: validation.FullNameSchema,
    onSubmit: async (values, actions) => {
      const displayName = values.name?.trim()
      const user: AppType.User = { displayName }

      await dispatch(updateUserName(user))
        .then(() => toast.success(`The full name has been updated.`))
        .catch((error) => {
          console.error(error)
          toast.error(validation.errorMessages.somethingWrong)
        })

      // Stop the loader in case of errors
      actions.setSubmitting(false)
      setEditable(false)
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TextField
          fullWidth
          type="email"
          label="Email Address"
          {...getFieldProps('email')}
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
        />

        <TextField
          fullWidth
          label="Full Name"
          {...getFieldProps('name')}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
          margin="normal"
          InputProps={{
            readOnly: !editable,
            endAdornment: (
              <InputAdornment position="end">
                {editable ? (
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ color: typography.colors.white }}
                    loading={isSubmitting}
                  >
                    Update
                  </LoadingButton>
                ) : (
                  <Tooltip title="Edit Full Name">
                    <IconButton onClick={() => setEditable(true)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Form>
    </FormikProvider>
  )
}

export default AccountDetailsForm
