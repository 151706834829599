import { Link, Typography } from '@mui/material'
import Wrapper from '@/components/elements/Wrapper'
import Nav from '@/components/elements/navigation/Nav'

const PrivacyPolicyPage: React.FC = () => {
  const email = 'support@ronastudio.com'
  return (
    <>
      <Nav />
      <Wrapper>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="caption" gutterBottom>
          Last Updated: 9/1/2023
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" gutterBottom>
          Welcome to <strong>RONA Team</strong>, a language translation and
          learning app developed by <strong>RONA Team</strong> ("we," "us," or
          "our"). This Privacy Policy is designed to help you understand how we
          collect, use, disclose, and safeguard your personal information when
          you use our mobile application ("App") and related services.
        </Typography>
        <Typography variant="body1" gutterBottom>
          By using our App, you consent to the practices described in this
          Privacy Policy. If you do not agree with the terms of this Privacy
          Policy, please do not use our App.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may collect certain information that can be used to identify you
          ("Personal Information") and non-personal information when you use our
          App. This may include:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Personal Information:</strong> When you create an account, we
          may collect your name, email address, and other contact information.
          If you choose to connect through social media platforms, we may also
          collect your social media profile information.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Usage Information:</strong> We may collect information about
          your interactions with the App, including the words or texts you
          translate, languages selected, and other usage patterns.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Device Information:</strong> We may collect information about
          your device, such as the type of device you use, operating system
          version, and unique device identifiers.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Location Information:</strong> With your consent, we may
          collect your location information to provide localized content and
          services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use the collected information for various purposes, including:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Providing Services:</strong> To provide you with language
          translation and learning services through our App.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Personalization:</strong> To personalize your experience by
          suggesting translations and learning content relevant to your
          preferences.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Communication:</strong> To send you notifications, updates,
          and promotional materials related to the App. You can opt out of
          promotional communications at any time.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Improvement:</strong> To improve our App's functionality,
          features, and user experience.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Analytics:</strong> To analyze usage patterns, troubleshoot
          issues, and gather demographic information for aggregated statistical
          purposes.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Disclosure of Your Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may share your information with third parties under the following
          circumstances:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Service Providers:</strong> We may share your information with
          third-party service providers who assist us in operating our App and
          providing related services.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Legal Requirements:</strong> We may disclose your information
          if required by law, court order, or government request.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Business Transfers:</strong> In the event of a merger,
          acquisition, or sale of all or a portion of our assets, your
          information may be transferred as part of the transaction.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Your Choices
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can manage your personal information by updating your account
          settings within the App. You can also choose to disable location
          services or opt out of promotional communications.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Security
        </Typography>
        <Typography variant="body1" gutterBottom>
          We take reasonable measures to safeguard your personal information.
          However, no method of transmission or electronic storage is completely
          secure, and we cannot guarantee its absolute security.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Children's Privacy
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our App is not intended for children under the age of 13. We do not
          knowingly collect personal information from children under 13 years of
          age.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of any material changes by posting the
          updated Privacy Policy within the App.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or our privacy practices, please contact us at{' '}
          <Link href={`mailto:${email}`}>{email}</Link>.
        </Typography>
        <Typography variant="body1" gutterBottom>
          By using the RONA App, you acknowledge that you have read and
          understood this Privacy Policy and agree to the collection, use, and
          disclosure of your information as described herein.
        </Typography>
        <br />
        <Typography variant="h6" gutterBottom>
          Thank you for using RONA Team!
        </Typography>
        <br />
      </Wrapper>
    </>
  )
}

export default PrivacyPolicyPage
