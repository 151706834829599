import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from '@mui/material'
import { motion } from 'framer-motion'
import { AppDispatch } from '@store'
import { useAppDispatch } from '@hooks'
import { logInWithEmailAndPassword, logout } from '@reducers/authSlice'
import { initUserData } from '@reducers/user/initUserData'
import { history } from '@utils/history'
import { validation } from '@utils/validation'
import routes from '@/pages/routes'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

type LoginFormState = {
  email: string
  password: string
}

const handleLogin = async (
  credentials: LoginFormState,
  dispatch: AppDispatch,
) => {
  try {
    await dispatch(logInWithEmailAndPassword(credentials)).then((data) => {
      const userId: string = (data.payload as { id: string }).id
      dispatch(initUserData(userId)).then((res) => {
        if (res.type === 'initUserData/rejected') dispatch(logout())
        // Redirect to home if already logged in
        else history.navigate(routes.home)
      })
    })
  } catch (error) {
    console.error('Login or dispatch failed:', error)
  }
}

const LoginForm = () => {
  const dispatch = useAppDispatch()
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: validation.LoginSchema,
    onSubmit: async (values, actions) => {
      const credentials: LoginFormState = {
        email: values.email.trim(),
        password: values.password,
      }

      await handleLogin(credentials, dispatch)
      // stop the loader in case errors
      actions.setSubmitting(false)
    },
  })

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          component={motion.div}
          animate={{ transition: { staggerChildren: 0.55 } }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
            component={motion.div}
            initial={{ opacity: 0, y: 40 }}
            animate={style.fadeInUp.animate}
          >
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email Address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 30 }}
            animate={style.fadeInUp.animate}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps('remember')}
                    checked={values.remember}
                  />
                }
                label="Remember me"
              />

              <Link
                component={RouterLink}
                variant="subtitle2"
                color={typography.colors.blue}
                to={routes.forgot}
                underline="hover"
              >
                Forgot password?
              </Link>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ color: typography.colors.white }}
              loading={isSubmitting}
            >
              {isSubmitting ? 'Loading...' : 'Login'}
            </LoadingButton>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  )
}

export default LoginForm
