import AppType from '@/types/AppType'
import { wordAPI } from '@/db/wordAPI'

const translateWord = async (word: string, language: AppType.Language) => {
  return await wordAPI.translateWord(word, language)
}

const addWord = async (word: AppType.Word, userId: string) => {
  // we show collections in store only and dom't keep it in db
  delete word.collections
  return await wordAPI.addWord(word, userId)
}

const updateWord = async (word: AppType.Word, userId: string) => {
  // we show collections in store only and dom't keep it in db
  delete word.collections
  return await wordAPI.updateWord(word, userId)
}

const removeWord = async (wordId: string, userId: string) => {
  return await wordAPI.removeWord(wordId, userId)
}

const setLearnStatus = async (word: AppType.Word, userId: string) => {
  return await wordAPI.setLearnStatus(word, userId)
}

export const wordService = {
  translateWord,
  addWord,
  updateWord,
  removeWord,
  setLearnStatus,
}
