import { toast } from 'react-toastify'
import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import { Button, Stack } from '@mui/material'
import { AppDispatch } from '@store'
import { useAppDispatch } from '@hooks'
import { socialAuth } from '@reducers/authSlice'
import { initUserData } from '@reducers/user/initUserData'
import { addNewUser } from '@reducers/user/userSlice'
import { validation } from '@utils/validation'
import AppType from '@/types/AppType'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const handleSignup = async (
  authProviderType: string,
  dispatch: AppDispatch,
) => {
  try {
    const socialAuthAction = await dispatch(socialAuth(authProviderType))

    if (!socialAuth.fulfilled.match(socialAuthAction)) {
      throw new Error()
    }

    if (socialAuth.fulfilled.match(socialAuthAction)) {
      const { isNewUser, id, email, displayName, photoURL } =
        socialAuthAction.payload
      const user: AppType.User = {
        id,
        email,
        displayName,
        photoURL,
      }

      if (isNewUser) {
        const addNewUserAction = await dispatch(addNewUser(user))

        if (addNewUser.fulfilled.match(addNewUserAction)) {
          toast.success('Welcome!')
        } else {
          toast.error(validation.errorMessages.somethingWrong)
        }
      } else {
        dispatch(initUserData(id))
      }
    }
  } catch (error) {
    throw new Error()
  }
}

const SocialAuth = () => {
  const dispatch = useAppDispatch()

  const onGoogle = async () => {
    await handleSignup('google', dispatch)
  }

  const onFacebook = async () => {
    await handleSignup('facebook', dispatch)
  }

  return (
    <Stack direction="row" spacing={2}>
      <Button
        fullWidth
        size="large"
        variant="outlined"
        style={style.button.bold}
        onClick={onGoogle}
      >
        <GoogleIcon
          fontSize="medium"
          sx={{ color: typography.colors.google }}
        />
      </Button>

      <Button
        fullWidth
        size="large"
        variant="outlined"
        style={style.button.bold}
        onClick={onFacebook}
      >
        <FacebookIcon
          fontSize="medium"
          sx={{ color: typography.colors.blue }}
        />
      </Button>
    </Stack>
  )
}

export default SocialAuth
