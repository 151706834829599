const routes = {
  home: '/',
  words: '/words',
  addWord: '/words/add',
  collections: '/collections',
  addCollection: '/collections/add',
  viewCollection: '/collections/:collectionId',
  learn: '/learn',
  flashCardOptions: '/learn/flash-card-options',
  allMarkedToLearnWords: '/learn/all-marked-to-learn-words',
  wordsFromSelectedCollection: '/learn/words-from-selected-collection',
  learnSelectedCollection: '/learn/collections/:collectionId',
  profile: '/profile',
  signin: '/signin',
  signup: '/signup',
  forgot: '/forgot-password',
  privacyPolicy: '/privacy',
  faq: '/faq',
  support: '/support',
  reportBugs: '/report-bugs',
  termsAndConditions: '/terms-conditions',
  error: '/error',
}

export default routes
