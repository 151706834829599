import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@store'
import { logout } from '@reducers/authSlice'
import { initUserData } from '@reducers/user/initUserData'
import { validation } from '@/shared/utils/validation'
import { userService } from '@services/userService'
import AppType from '@/types/AppType'

type UserState = AppType.CommonState & AppType.User

export const initializeUser = (user?: AppType.User): AppType.User => {
  const { id, email, displayName, photoURL } = user || {}
  return {
    id,
    email,
    displayName,
    photoURL,
  }
}

const initialState: UserState = initializeUser()

export const addNewUser = createAsyncThunk<UserState, AppType.User>(
  'addNewUser',
  async (userData, thunkAPI) => {
    try {
      // The set function from Firebase's Realtime Database returns undefined upon successful completion so we don't need then().
      await userService.addUserToDB(userData).catch((error) => {
        throw error
      })
      return thunkAPI.fulfillWithValue(userData)
    } catch (error) {
      throw error
    }
  },
)

export const updateUserName = createAsyncThunk<UserState, AppType.User>(
  'updateUserName',
  async (userData, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState
      const userId = state?.user.id
      if (!userId) throw new Error(validation.errorMessages.invalidId)

      await userService
        .updateUserName(userId, userData.displayName!)
        .catch((error) => {
          throw error
        })

      return thunkAPI.fulfillWithValue(userData)
    } catch (error) {
      throw error
    }
  },
)

export const deleteAccount = createAsyncThunk(
  'deleteAccount',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState
      const userId = state?.user.id
      if (!userId) throw new Error(validation.errorMessages.invalidId)

      await userService.deleteAccount(userId).catch((error) => {
        throw error
      })
    } catch (error) {
      throw error
    }
  },
)

// Slices
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // initUserData user
    builder.addCase(initUserData.fulfilled, (state, action) => {
      const { user } = action.payload
      const { id, email, displayName, photoURL } = user
      Object.assign(state, {
        id,
        email,
        displayName,
        photoURL,
        error: initialState.error,
      })
    })
    builder.addCase(initUserData.rejected, (state) => {
      Object.assign(state, initialState)
    })

    // addNewUser
    builder.addCase(addNewUser.fulfilled, (state, action) => {
      const { id, email, displayName, photoURL } = action.payload
      Object.assign(state, {
        id,
        email,
        displayName,
        photoURL,
        errorSelector: initialState.error,
      })
    })
    builder.addCase(addNewUser.rejected, (state, action) => {
      Object.assign(state, initialState)
      state.error = action.error
    })

    // updateUserName
    builder.addCase(updateUserName.fulfilled, (state, action) => {
      return {
        ...state,
        displayName: action.payload.displayName,
      }
    })
    builder.addCase(updateUserName.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // deleteAccount
    builder.addCase(deleteAccount.fulfilled, (state) => {
      Object.assign(state, initialState)
    })
    builder.addCase(deleteAccount.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // logout
    builder.addCase(logout.fulfilled, (state) => {
      Object.assign(state, initialState)
    })
  },
})

// Selectors
export const userSelector = (state: RootState) => (state ? state.user : {})

export const userIdSelector = createSelector(userSelector, (user) => user.id)

export const userDataSelector = createSelector(userSelector, (user) => user)

// Export the reducer, either as a default or named export
export default userSlice.reducer
