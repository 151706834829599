import { Stack } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import DownloadOnAppStoreBanner from '@/components/elements/AppStoreButton'

const HomeHeader = () => {
  return (
    <AppBar position="static">
      <Toolbar style={styles.toolbar}>
        <img src="header.jpg" alt="Header" style={styles.header} />
        <Stack sx={styles.appStoreBanner}>
          <DownloadOnAppStoreBanner />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

const styles = {
  appStoreBanner: {
    position: 'absolute',
    bottom: '15px',
    left: '15px',
    cursor: 'pointer',
  },

  header: {
    width: '100%',
    height: '100%',
    objectFit: 'cover' as 'cover',
  },

  toolbar: {
    height: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative' as 'relative',
    padding: 0,
  },
}

export default HomeHeader
