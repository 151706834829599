import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'

interface FaqItemProps {
  question: string
  answer: string
}

const FaqItem: React.FC<FaqItemProps> = ({ question, answer }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

const Faq = () => {
  const faqs = [
    {
      question: 'What is RONA?',
      answer:
        'RONA is a language translation and learning app developed by RONA LLC. It allows you to translate words and texts from various languages and provides tools to help you learn new words and phrases.',
    },
    {
      question: 'How does RONA work?',
      answer:
        'RONA uses advanced language processing technology to provide accurate translations. Simply input the word or text you want to translate, select the target language, and the app will provide you with the translation. You can also explore learning features to enhance your vocabulary.',
    },
    {
      question: 'Is RONA available for free?',
      answer:
        'Yes, RONA offers a free version with basic translation and learning features. However, we also offer a premium subscription plan that includes advanced features, ad-free experience, and more extensive language support. Check the app for subscription details.',
    },
    {
      question: 'Which languages does RONA support?',
      answer:
        'RONA supports a wide range of languages, including but not limited to English, Spanish, French, German, Chinese, Japanese, and more. The app allows you to translate between these languages and learn new words in various languages.',
    },
    {
      question: 'How can I create an account?',
      answer:
        "To create an account, simply download the RONA app from your device's app store and follow the on-screen instructions to sign up. You can also choose to log in using your social media accounts for convenience.",
    },
    {
      question: 'Is my personal information safe?',
      answer:
        'We take your privacy seriously. Please refer to our Privacy Policy for detailed information about how we collect, use, and protect your personal information. You can find the Privacy Policy within the app and on our website.',
    },
    {
      question: 'Can I use RONA offline?',
      answer:
        'Basic translation features may work offline, but certain advanced features and content may require an internet connection. Make sure to check your app settings for offline usage options.',
    },
    {
      question: 'How can I improve my vocabulary with RONA?',
      answer:
        'RONA offers learning tools such as flashcards, quizzes, and personalized vocabulary lists to help you expand your vocabulary. You can practice and reinforce your knowledge of translated words and phrases through these features.',
    },
    {
      question: 'Can I contribute my own translations?',
      answer:
        "Currently, RONA's translations are generated through advanced algorithms. While you can't directly contribute translations, we continuously work to improve our database to provide accurate and reliable translations.",
    },
    {
      question: 'How often does RONA update its content?',
      answer:
        'We strive to provide the most up-to-date content, including translations and learning materials. Updates to the app are released periodically to enhance the user experience and offer new features.\n\nIf you have more questions or need assistance, feel free to contact our support team through the app or visit our website for further information.',
    },
  ]

  return (
    <>
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </>
  )
}

export default Faq
