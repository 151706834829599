import { getCollectionsForWord } from '@utils/getCollectionsForWord'
import AppType from '@/types/AppType'

/* List of words with collections */
export const getWordsWithCollections = (
  words: AppType.Word[],
  collections: AppType.Collection[],
): AppType.Word[] => {
  return words
    ? Object.entries(words)
        .map(([id, word]) => {
          const updatedWord = {
            ...word,
            collections: getCollectionsForWord(word, collections),
          }
          return { [id]: updatedWord }
        })
        .reduce((acc, wordObj) => ({ ...acc, ...wordObj }), [])
    : []
}
