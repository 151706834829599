import { authAPI } from '@/db/authAPI'
import { AuthCredential } from '@firebase/auth'

const addUserWithEmailAndPassword = async (email: string, password: string) => {
  return await authAPI.addUserWithEmailAndPassword(email, password)
}

const addUserWithSocPopup = async (authProviderType: string) => {
  return await authAPI.addUserWithSocPopup(authProviderType)
}

const logInWithEmailAndPassword = async (email: string, password: string) => {
  return await authAPI.logInWithEmailAndPassword(email, password)
}

const logInWithCredential = async (credentials: AuthCredential) => {
  return await authAPI.logInWithCredential(credentials)
}

const passwordResetEmail = async (email: string) => {
  return await authAPI.passwordResetEmail(email)
}

const updateUserPassword = async (oldPassword: string, newPassword: string) => {
  return await authAPI.updateUserPassword(oldPassword, newPassword)
}

const logOut = async () => {
  return await authAPI.logOut()
}

export const authService = {
  addUserWithEmailAndPassword,
  addUserWithSocPopup,
  logInWithEmailAndPassword,
  logInWithCredential,
  passwordResetEmail,
  updateUserPassword,
  logOut,
}
