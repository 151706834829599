import { toast } from 'react-toastify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { initializeCollections } from '@reducers/collectionSlice'
import { initializeLanguage } from '@reducers/languageSlice'
import { initializeUser } from '@reducers/user/userSlice'
import { initializeWords } from '@reducers/wordSlice'
import { validation } from '@utils/validation'
import { userService } from '@services/userService'
import AppType from '@/types/AppType'

type InitState = AppType.CommonState & AppType.Auth & AppType.UserData

// Define an async thunk for initializing user data
export const initUserData = createAsyncThunk<InitState, string | undefined>(
  'initUserData',
  async (userId, thunkAPI) => {
    if (typeof userId !== 'string') {
      toast.error(validation.errorMessages.invalidId)
      throw validation.errorMessages.invalidId
    }

    try {
      // Fetch user data from the service
      const data = await userService.initUserData(userId)

      // If user has been deleted, show a message and reject the promise
      if (data?.deletedAt) {
        toast.error(validation.errorMessages.accountDeleted)
        throw new Error(validation.errorMessages.accountDeleted)
      }

      // Initialize user data
      const user: AppType.User = initializeUser(data)
      const words: AppType.Word[] = initializeWords(
        data.words,
        data.collections,
      )
      const collections: AppType.Collection[] = initializeCollections(
        data.collections,
      )
      const language: AppType.Language = initializeLanguage(data.language)

      const userData: AppType.UserData = {
        user,
        words,
        collections,
        language,
      }

      return thunkAPI.fulfillWithValue(userData)
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: (error as Error).message })
    }
  },
)
