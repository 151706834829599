import AppType from '@/types/AppType'

const sortByCreated = <T extends AppType.Word | AppType.Collection>(
  items: T[],
): T[] => {
  return Object.values(items).sort((a, b) => {
    const dateA = a.created ? new Date(a.created) : null
    const dateB = b.created ? new Date(b.created) : null

    if (!dateA && !dateB) {
      return 0 // If both dates are undefined, consider them equal
    } else if (!dateA) {
      return 1 // If dateA is undefined, consider it greater
    } else if (!dateB) {
      return -1 // If dateB is undefined, consider it greater
    }

    return dateB.getTime() - dateA.getTime()
  })
}

export default sortByCreated
