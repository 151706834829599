import { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined'
import { Box, Button, LinearProgress } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useAppDispatch } from '@/shared/redux/hooks'
import { setLearnStatus } from '@/shared/redux/reducers/wordSlice'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import AppType from '@/types/AppType'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import ResultCard from '@/components/learn/ResultCard'
import { typography } from '@/styles/typography'

type FlashCardProps = {
  words: AppType.Word[]
}

const FlashCard = (props: FlashCardProps) => {
  const { words } = props
  const dispatch = useAppDispatch()

  const initialState = {
    disable: false,
    reset: 0,
  }

  const [progress, setProgress] = useState(initialState.reset)
  const [word, setWord] = useState(words[initialState.reset])
  const [isShowTranslation, setShowTranslation] = useState(initialState.disable)
  const [learnAgain, setLearnAgain] = useState(initialState.reset)
  const [correct, setCorrect] = useState(initialState.reset)
  const [isShowResult, setShowResult] = useState(initialState.disable)
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(
    initialState.disable,
  )

  const resetStates = () => {
    setProgress(initialState.reset)
    setWord(words[initialState.reset])
    setShowTranslation(initialState.disable)
    setLearnAgain(initialState.reset)
    setCorrect(initialState.reset)
    setShowResult(initialState.disable)
    setConfirmDialogOpen(initialState.disable)
  }

  const onNextWord = (word: AppType.Word, isForLearn: boolean) => {
    const next = progress + 1
    setProgress(next)

    const updatedWord: AppType.Word = { ...word, isForLearn }
    dispatch(setLearnStatus(updatedWord))

    if (isForLearn) {
      // if learn again
      setLearnAgain((prev) => prev + 1)
    } else {
      // if correct answer
      setCorrect((prev) => prev + 1)
    }

    if (next >= words.length) {
      setTimeout(() => {
        setShowResult(true)
      }, 500)
    } else {
      setWord(words[next])
      setShowTranslation(false)
    }
  }

  const handleQuitConfirm = () => {
    history.navigate(routes.learn)
    setConfirmDialogOpen(false)
  }

  return (
    <>
      {isShowResult ? (
        <ResultCard
          wordsLength={words.length}
          progress={progress}
          correct={correct}
          learnAgain={learnAgain}
          onClose={() => setConfirmDialogOpen(true)}
          onStartOver={() => resetStates()}
        />
      ) : (
        <Card sx={{ p: 1 }}>
          <CardHeader
            action={
              <IconButton onClick={() => setConfirmDialogOpen(true)}>
                <ClearIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Typography
              align="center"
              variant="h5"
              sx={{
                color: isShowTranslation ? typography.colors.primary : null,
              }}
            >
              {isShowTranslation ? word.translation : word.original}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'row',
                mt: 5,
                mb: 2,
              }}
            >
              {isShowTranslation ? (
                <>
                  <Button
                    size="large"
                    variant="contained"
                    sx={{ color: typography.colors.white, width: 160 }}
                    onClick={() => onNextWord(word, false)}
                  >
                    Correct
                  </Button>
                  <Button
                    size="large"
                    variant="outlined"
                    sx={{ width: 160 }}
                    onClick={() => onNextWord(word, true)}
                  >
                    Learn again
                  </Button>
                </>
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  sx={{ color: typography.colors.white, width: 160 }}
                  onClick={() => setShowTranslation(true)}
                >
                  Translate me
                </Button>
              )}
            </Box>
            <Typography align="center" variant="body2">
              {progress} from {words.length}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <LinearProgress
                variant="determinate"
                value={(progress / words.length) * 100}
                sx={{
                  width: '100%',
                  height: 10,
                  borderRadius: 5,
                }}
              />
            </Box>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              padding: 2,
            }}
          >
            {word.from} <EastOutlinedIcon sx={{ ml: 1, mr: 1 }} /> {word.to}
          </CardActions>
        </Card>
      )}

      {isConfirmDialogOpen ? (
        <ConfirmationDialog
          isOpen={isConfirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          onConfirm={handleQuitConfirm}
          title="Quit"
          message="Are you sure you want to quit?"
          confirmLabel="Yes"
          isRedButton={true}
        />
      ) : null}
    </>
  )
}

export default FlashCard
