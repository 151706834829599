import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@store'
import { logout } from '@reducers/authSlice'
import { initUserData } from '@reducers/user/initUserData'
import sortByCreated from '@utils/sortByCreated'
import { validation } from '@utils/validation'
import { collectionService } from '@services/collectionService'
import AppType from '@/types/AppType'

export type WordAndCollectionState = {
  word: AppType.Word
  collection: AppType.Collection
}
type CollectionState = AppType.CommonState & AppType.Collection
type CollectionsState = AppType.CommonState & AppType.Collection[]

export const initializeCollections = (
  collections?: AppType.Collection[],
): CollectionsState => collections ?? []

const initialState: CollectionsState = initializeCollections()

export const addCollection = createAsyncThunk<
  CollectionState,
  AppType.Collection
>('addCollection', async (collection, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState
    const userId = state?.user.id
    if (!userId) throw new Error(validation.errorMessages.invalidId)

    await collectionService.addCollection(collection, userId).catch((error) => {
      throw error
    })

    return thunkAPI.fulfillWithValue(collection)
  } catch (error) {
    throw error
  }
})

export const updateCollection = createAsyncThunk<
  CollectionState,
  AppType.Collection
>('updateCollection', async (collection, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState
    const userId = state?.user.id
    if (!userId) throw new Error(validation.errorMessages.invalidId)

    await collectionService
      .updateCollection(collection, userId)
      .catch((error) => {
        throw error
      })

    return thunkAPI.fulfillWithValue(collection)
  } catch (error) {
    throw error
  }
})

export const removeCollection = createAsyncThunk<
  CollectionState,
  AppType.Collection
>('removeCollection', async (collection, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState
    const userId = state?.user.id
    const collectionId = collection.id

    if (!userId || !collectionId)
      throw new Error(validation.errorMessages.invalidId)

    await collectionService
      .removeCollection(collectionId, userId)
      .catch((error) => {
        throw error
      })

    return thunkAPI.fulfillWithValue(collection)
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: (error as Error).message })
  }
})

export const addWordToCollection = createAsyncThunk<
  CollectionState,
  WordAndCollectionState
>('addWordToCollection', async (request, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState
    const userId = state?.user.id
    const wordId = request.word.id
    const collection = request.collection

    if (!userId || !wordId || !collection.id)
      throw new Error(validation.errorMessages.invalidId)

    await collectionService
      .addWordToCollection(collection, wordId, userId)
      .catch((error) => {
        throw error
      })

    const updatedCollection: AppType.Collection = {
      ...collection,
      wordIds: {
        ...collection.wordIds,
        [`${wordId}`]: true,
      },
    }

    return thunkAPI.fulfillWithValue(updatedCollection)
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: (error as Error).message })
  }
})

export const removeWordFromCollection = createAsyncThunk<
  CollectionState,
  WordAndCollectionState
>('removeWordFromCollection', async (request, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState
    const userId = state?.user.id
    const { word, collection } = request
    const { id: wordId } = word

    if (!userId || !wordId || !collection.id)
      throw new Error(validation.errorMessages.invalidId)

    await collectionService.removeWordFromCollection(
      collection.id,
      wordId,
      userId,
    )

    return thunkAPI.fulfillWithValue(collection)
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: (error as Error).message })
  }
})

export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // initUserData collections
    builder.addCase(initUserData.fulfilled, (state, action) => {
      return action.payload.collections
    })
    builder.addCase(initUserData.rejected, () => [])

    // addCollection
    builder.addCase(addCollection.fulfilled, (state, action) => {
      const { id } = action.payload
      const newCollection = {
        ...action.payload,
        created: new Date().toISOString(),
      }
      return { ...state, ...{ [`${id}`]: newCollection } }
    })
    builder.addCase(addCollection.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // updateCollection
    builder.addCase(updateCollection.fulfilled, (state, action) => {
      const id = action.payload.id!
      return {
        ...state,
        [id]: {
          ...state[id as any],
          ...action.payload,
        },
      }
    })
    builder.addCase(updateCollection.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // removeCollection
    builder.addCase(removeCollection.fulfilled, (state, action) => {
      const id = action.payload.id
      const newState = { ...state }
      delete newState[id as any]
      return newState
    })
    builder.addCase(removeCollection.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // addWordToCollection,
    builder.addCase(addWordToCollection.fulfilled, (state, action) => {
      const collection = action.payload
      const collectionId = collection.id!

      return {
        ...state,
        [collectionId]: {
          ...state[collectionId as any],
          ...collection,
        },
      }
    })
    builder.addCase(addWordToCollection.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // removeWordFromCollection
    builder.addCase(removeWordFromCollection.fulfilled, (state, action) => {
      const collection = action.payload
      const collectionId = collection.id!

      return {
        ...state,
        [collectionId]: {
          ...state[collectionId as any],
          ...collection,
        },
      }
    })
    builder.addCase(removeWordFromCollection.rejected, (state, action) => {
      state = initialState
      state.error = action.error
    })

    // logout
    builder.addCase(logout.fulfilled, () => [])
  },
})

// Selectors
export const collectionsSelector = (state: RootState) =>
  state ? state.collections : []

export const allCollectionsSelector = createSelector(
  collectionsSelector,
  (collections) => Object.values(collections) ?? [],
)

export const sortByDateCollectionsSelector = createSelector(
  collectionsSelector,
  (collections) => sortByCreated(collections),
)

export const collectionByIdSelector =
  (collectionId: string) => (state: RootState) => {
    const collections = allCollectionsSelector(state)
    return collections.find((c) => c.id === collectionId)
  }

export default collectionSlice.reducer
